<template>
  <div id="MedicalRecordsExport" class="fontForAll text-black">
    <div id="MedicalRecordsViewDetail" v-if="form && form.person">
      <!-- Page 1 -->
      <div ref="PatientInfo">
        <div id="MedicalRecordsViewDetailPage1" class="fontForAll text-black">
          <div class="row">
            <div class="col-4 flex">
              <div
                class="hodo-print mt-11 flex flex-col items-start justify-center"
              >
                <div class="fs-17 uppercase">
                  <div class="text-center font-bold">
                    {{
                      $globalClinic && $globalClinic.organization
                        ? $globalClinic.organization.authority_in_charge
                        : ""
                    }}
                  </div>
                  <div class="font-bold">
                    {{
                      $globalClinic && $globalClinic.organization
                        ? $globalClinic.organization.name
                        : ""
                    }}
                  </div>
                  <!-- <div>{{$globalClinic.name }}</div> -->
                </div>
                <div>
                  <div class="fs-17">
                    <label class="mr-1">Buồng:</label>
                    <span>{{
                      form.admission_room || "....................."
                    }}</span>
                  </div>
                  <div class="fs-17">
                    <label class="mr-1">Giường:</label>
                    <span>{{
                      form.admission_bed || "..................."
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-4 hodo-print mt-11 flex justify-center items-center uppercase whitespace-nowrap"
            >
              <span class="fs-32 font-bold">{{
                templateInfo.template_title
              }}</span>
            </div>
            <div class="col-4 mt-11 text-right hodo-print">
              <div class="inline-block text-left">
                <div class="fs-17">
                  Số vào viện:
                  {{ form.admission_hospital_number || person.ref_id }}
                </div>
                <div class="fs-17">
                  Số lưu trữ:
                  {{ form.archive_number || ".........................." }}
                </div>
                <div class="fs-17">Mã YT: ......./......./......./.......</div>
                <div class="fs-17">
                  .............................................
                </div>
                <!-- <div class="fs-17">Mã bệnh: {{form.treat_dept_main_code}}</div> -->
              </div>
            </div>
          </div>

          <div class="fs-14 mt-2">
            <div class="hodo-print">
              <div class="font-bold fs-20">I. HÀNH CHÍNH</div>
            </div>
            <div class="mt-0.5 row">
              <div class="col-6">
                <div class="flex items-center hodo-print">
                  <label class="fs-18">
                    1. Họ và tên
                    <!-- <span class="italic fs-16">(In hoa)</span>: -->
                  </label>
                  <span
                    class="fs-18 font-bold uppercase ml-2"
                    v-if="person.name"
                    >{{ person.name }}</span
                  >
                  <span class="fs-18 content-none" v-else></span>
                </div>
              </div>

              <div class="col-6 hodo-print">
                <div class="flex justify-between items-center fs-18">
                  <label>2. Sinh ngày:</label>
                  <BoxNumber :str="getBirthday()" />
                </div>
              </div>
            </div>

            <div class="mt-0.5 row">
              <div class="col-6">
                <div class="row">
                  <div class="col-3 flex items-center hodo-print">
                    <label class="fs-18">3. Giới tính:</label>
                  </div>
                  <div
                    class="col-6 flex items-center justify-around hodo-print"
                  >
                    <label class="flex items-center fs-18">
                      <span>1. Nam</span>
                      <input
                        type="radio"
                        id="nam"
                        :disabled="!isEdit"
                        :value="1"
                        class="mr-2"
                        v-model="person.gender"
                      />
                      <span class="box-item border-box fs-24 ml-1">
                        <span
                          class="fs-24 -mt-1 not-italic"
                          style="line-height: 1"
                        >
                          {{ person.gender === 1 ? "x" : "" }}
                        </span>
                      </span>
                    </label>
                    <label class="flex items-center fs-18">
                      <span>2. Nữ</span>
                      <input
                        type="radio"
                        id="nu"
                        :disabled="!isEdit"
                        :value="2"
                        class="mr-2"
                        v-model="person.gender"
                      />
                      <span class="box-item border-box fs-24 ml-1">
                        <span
                          class="fs-24 -mt-1 not-italic"
                          style="line-height: 1"
                        >
                          {{ person.gender === 2 ? "x" : "" }}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-6 hodo-print">
                <div class="flex justify-between items-center fs-18">
                  <div class="flex w-full">
                    <label class="whitespace-nowrap mr-1"
                      >4. Nghề nghiệp:</label
                    >
                    <div class="content-none" v-if="!form.career_name"></div>
                    <span v-else class>{{ form.career_name }}</span>
                  </div>
                  <BoxNumber :str="form.career_code" />
                </div>
              </div>
            </div>

            <div class="mt-0.5 row">
              <div class="col-6 hodo-print">
                <div class="flex justify-between items-center fs-18">
                  <div class="flex w-full">
                    <label class="whitespace-nowrap mr-1 fs-18"
                      >5. Dân tộc:</label
                    >
                    <div class="content-none" v-if="!form.ethnic_name"></div>
                    <span v-else>{{ form.ethnic_name }}</span>
                  </div>
                  <BoxNumber :str="form.ethnic_code" />
                </div>
              </div>
              <div class="col-6 hodo-print">
                <div class="flex justify-between items-center fs-18">
                  <div class="flex w-full">
                    <label class="whitespace-nowrap mr-1 fs-18"
                      >6. Ngoại kiều:</label
                    >
                    <div
                      class="content-none"
                      v-if="!form.nationality_name"
                    ></div>
                    <span>{{ form.nationality_name }}</span>
                  </div>
                  <BoxNumber :str="form.nationality_code" />
                </div>
              </div>
            </div>

            <div class="row mt-0.5 items-start">
              <div class="col-6 hodo-print">
                <div class="flex justify-between items-center fs-18">
                  <div
                    class="w-full"
                    :class="!form.address_detail ? 'flex' : 'overflow-hidden'"
                  >
                    <label
                      class="whitespace-nowrap mr-1 fs-18"
                      :class="form.address_detail ? 'float-left' : ''"
                    >
                      7. Địa chỉ số nhà:
                    </label>
                    <div class="content-none" v-if="!form.address_detail"></div>
                    <span class="fs-18" v-else>{{ form.address_detail }}</span>
                  </div>
                </div>
              </div>
              <div class="col-6 hodo-print">
                <div class="flex justify-between items-center fs-18">
                  <div class="flex w-full">
                    <label class="whitespace-nowrap fs-18 mr-1"
                      >Xã, phường:</label
                    >
                    <div
                      class="content-none"
                      v-if="!form.address_wards_name"
                    ></div>
                    <span class="fs-18">{{ form.address_wards_name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-0.5">
              <div class="col-6 hodo-print">
                <div class="flex justify-between items-center fs-18">
                  <div class="flex w-full">
                    <label class="whitespace-nowrap mr-1 fs-18"
                      >Huyện (Q, Tx):</label
                    >
                    <div
                      class="content-none"
                      v-if="!form.address_district_name"
                    ></div>
                    <span class="fs-18" v-else>{{
                      form.address_district_name
                    }}</span>
                  </div>
                  <BoxNumber :str="form.address_district_code" />
                </div>
              </div>
              <div class="col-6 hodo-print">
                <div class="flex justify-between items-center fs-18">
                  <div class="flex w-full">
                    <label class="whitespace-nowrap mr-1 fs-18"
                      >Tỉnh, thành phố:</label
                    >
                    <div
                      class="content-none"
                      v-if="!form.address_province_name"
                    ></div>
                    <span v-else class="fs-18">{{
                      form.address_province_name
                    }}</span>
                  </div>
                  <BoxNumber :str="form.address_province_code" />
                </div>
              </div>
            </div>

            <div class="row mt-0.5">
              <div class="col-6 hodo-print">
                <div class="flex justify-between items-center fs-18">
                  <div class="flex w-full">
                    <label class="whitespace-nowrap mr-1 fs-18"
                      >8. Nơi làm việc:</label
                    >
                    <div class="content-none" v-if="!form.work_at"></div>
                    <span v-else class="fs-18">{{ form.work_at }}</span>
                  </div>
                </div>
              </div>
              <!-- form.insurance_type -->
              <div class="col-6 hodo-print">
                <div
                  class="flex justify-between whitespace-nowrap items-center fs-18"
                >
                  <label class="self-center fs-18">9.Đối tượng BHYT:</label>
                  <span for="BHYT" class="fs-14 italic flex ml-1">
                    <span class="fs-14" style="font-size: 1rem"
                      >(1.BHYT 2.Thu phí 3.Miễn 4.Khác)</span
                    >
                  </span>
                  <BoxNumber :str="form.insurance_type" />
                </div>
              </div>
            </div>
            <!-- insurance_start_date -->
            <div class="row mt-0.5">
              <div class="col-6 items-center hodo-print">
                <div class="fs-18">
                  <div class="flex">
                    <div class="flex fs-18">
                      <span class="whitespace-nowrap mr-1"
                        >10. BHYT: giá trị từ</span
                      >
                      <div
                        style="width: 7rem"
                        v-if="!form.insurance_start_date"
                      >
                        ......../......../........
                      </div>
                      <div class="fs-18" v-else>
                        {{ formatDateView(form.insurance_start_date) }}
                      </div>
                    </div>
                    <!-- <div class="flex">
                    <label class="mr-1 whitespace-nowrap fs-18">10. BHYT: giá trị từ</label>
                  <div class="content-none" v-if="!form.insurance_start_date"></div>-->
                    <!-- <div v-else class="">{{ formatDateView(form.insurance_start_date) }}</div> -->
                    <!-- </div> -->
                    <!-- insurance_expire -->
                    <div class="flex fs-18 ml-1">
                      <span class="whitespace-nowrap mr-1">đến</span>
                      <div style="width: 8rem" v-if="!form.insurance_expire">
                        ......../......../........
                      </div>
                      <div class="fs-18" v-else>
                        {{ formatDateView(form.insurance_expire) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 hodo-print">
                <div class="flex items-center fs-18">
                  <label class="mr-2">Số:</label>
                  <BoxNumber
                    class="w-full"
                    bhyt="true"
                    :str="form.insurance_number"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-0.5">
              <div class="flex hodo-print">
                <label class="whitespace-nowrap mr-1 fs-18"
                  >11. Họ tên địa chỉ người nhà khi cần báo tin:</label
                >
                <div
                  class="content-none w-3/4"
                  v-if="!form.emergency_contact"
                ></div>
                <span v-else class="w-3/4 fs-18">{{
                  form.emergency_contact
                }}</span>
              </div>
            </div>
            <div class="row mt-0.5">
              <div class="col-6 flex flex-col">
                <div class="mt-auto">
                  <div class="content-none w-full"></div>
                </div>
              </div>
              <div class="col-6 hodo-print">
                <div class="flex w-full fs-18">
                  <label class="whitespace-nowrap mr-1 fs-18"
                    >Điện thoại số:</label
                  >
                  <div
                    class="content-none"
                    v-if="!form.person.contact_phone"
                  ></div>
                  <div class="fs-18" v-else>
                    {{ form.person.contact_phone }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-0.5">
            <div class="hodo-print">
              <div class="font-bold fs-20">II. QUẢN LÝ NGƯỜI BỆNH</div>
            </div>
            <div class="border-box">
              <div class="border-bottom-item">
                <div class="row pl-1">
                  <div class="col-6 hodo-print pr-0">
                    <div class="border-right-item pt-0.5 flex items-end fs-16">
                      <label class="fs-16 whitespace-nowrap mr-1"
                        >12. Vào viện:</label
                      >
                      <div class="content-none" v-if="!form.reception_at"></div>
                      <span class="fs-16" v-else>{{
                        getDate(form.reception_at)
                      }}</span>
                    </div>
                  </div>
                  <!-- reception_from -->
                  <div class="col-6 hodo-print">
                    <div
                      class="flex justify-between items-center pt-0.5 pr-0.5"
                    >
                      <div class="col-4">
                        <label class="mr-2 fs-16 whitespace-nowrap"
                          >14. Nơi giới thiệu:</label
                        >
                      </div>
                      <label class="italic flex items-center">
                        <span class="fs-15">1.Cơ quan Y tế</span>
                      </label>
                      <label class="italic flex items-center">
                        <span class="fs-15">2. Tự đến</span>
                      </label>
                      <label class="italic flex items-center">
                        <span class="fs-15">3. Khác</span>
                      </label>
                      <BoxNumber :str="form.reception_from" />
                    </div>
                  </div>
                </div>

                <div class="row pl-1">
                  <div class="col-6 hodo-print pr-0">
                    <div class="flex justify-between border-right-item py-0.5">
                      <label class="self-center fs-16"
                        >13. Tiếp nhận tại:</label
                      >
                      <label for="capcuu" class="italic flex items-center">
                        <span class="fs-16">1.Cấp cứu</span>
                        <input
                          :disabled="!isEdit"
                          type="radio"
                          id="capcuu"
                          :value="1"
                          class="mr-2"
                          v-model="form.reception_location"
                        />
                        <span class="box-item border-box fs-24 ml-1">
                          <span
                            class="fs-24 -mt-1 not-italic"
                            style="line-height: 1"
                          >
                            {{ form.reception_location === 1 ? "x" : "" }}
                          </span>
                        </span>
                      </label>
                      <label for="KKB" class="italic flex items-center">
                        <span class="fs-16">2.KKB</span>
                        <input
                          :disabled="!isEdit"
                          type="radio"
                          id="KKB"
                          :value="2"
                          class="mr-2"
                          v-model="form.reception_location"
                        />
                        <span class="box-item border-box fs-24 ml-1">
                          <span
                            class="fs-24 -mt-1 not-italic"
                            style="line-height: 1"
                          >
                            {{ form.reception_location === 2 ? "x" : "" }}
                          </span>
                        </span>
                      </label>
                      <label
                        for="khoadieutri"
                        class="italic flex items-center pr-0.25"
                      >
                        <span class="fs-16"> 3.Khoa điều trị </span>
                        <input
                          :disabled="!isEdit"
                          type="radio"
                          id="khoadieutri"
                          :value="3"
                          class="mr-2"
                          v-model="form.reception_location"
                        />
                        <span class="box-item border-box fs-24 ml-1">
                          <span
                            class="fs-24 -mt-1 not-italic"
                            style="line-height: 1"
                          >
                            {{ form.reception_location === 3 ? "x" : "" }}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-6 hodo-print">
                    <div class="flex fs-16 items-center py-0.5 pr-0.5">
                      <label class="mr-2 fs-16">Vào viện lần thứ</label>
                      <BoxNumber :str="form.reception_count" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pl-1">
                <div class="col-6 pr-0 border-right-item">
                  <div class="py-0.5">
                    <div class="row fs-16">
                      <div class="col-3"></div>
                      <div class="col-3 hodo-print">
                        <div class="ml-3 fs-16">Khoa</div>
                      </div>
                      <div class="col-2 hodo-print">
                        <div class="fs-16">Ngày</div>
                      </div>
                      <div class="col-4 hodo-print">
                        <div class="text-right fs-16 mr-1">Số ngày Đ.Trị</div>
                      </div>
                    </div>
                    <div
                      class="row mt-0.5 items-center"
                      v-for="(department, index) in departments"
                      :key="index"
                    >
                      <div class="col-2 hodo-print">
                        <span
                          class="leading-none fs-16 inline-flex"
                          v-if="index === 0"
                        >
                          <span class="mr-1">15.</span>
                          <span>Vào khoa</span>
                        </span>
                        <span
                          class="leading-none fs-16 inline-flex"
                          v-if="index === 1"
                        >
                          <span class="mr-1">16.</span>
                          <span>Chuyển khoa</span>
                        </span>
                      </div>
                      <div class="col-2 hodo-print">
                        <div class="border-box box-h-item fs-16">
                          {{ department.department }}
                        </div>
                      </div>
                      <div class="col-5 hodo-print">
                        <div class="flex fs-16 p-0">
                          <div
                            class="content-none mb-0"
                            v-if="!department.start_time"
                          ></div>
                          <div class="fs-16" v-else>
                            {{ getDate2(department.start_time) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-3 hodo-print">
                        <div
                          class="text-center flex items-center justify-end mr-2"
                        >
                          <BoxNumber class="ml-auto" :str="department.days" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- transfer_hospital -->
                <div class="col-6 items-center py-0.5">
                  <div class="hodo-print">
                    <div
                      class="flex mt-0.5 pr-0.5 justify-between items-center whitespace-nowrap"
                    >
                      <div class="self-center fs-16">17. Chuyển viện:</div>
                      <label for="tuyentren" class="italic flex items-center">
                        <span class="fs-15 ml-2">(1.Tuyến trên</span>
                        <span class="fs-15 ml-2">2.Tuyến dưới</span>
                        <span class="fs-15 ml-2">3.Chuyển khoa)</span>
                      </label>
                      <BoxNumber :str="form.transfer_hospital" />
                    </div>
                  </div>
                  <div class="hodo-print">
                    <div class="flex pr-0.5 mt-1">
                      <label class="w-28 fs-16">- Chuyển đến:</label>
                      <div
                        class="content-none mb-1"
                        v-if="!form.transfer_to"
                      ></div>
                      <span v-else class="fs-16">{{ form.transfer_to }}</span>
                    </div>
                    <div class="content-none w-full mb-1 pt-3"></div>
                  </div>
                  <div class="mt-1">
                    <div class="col-12 hodo-print">
                      <div class="flex pr-0.5">
                        <label class="w-22 fs-16 whitespace-nowrap"
                          >16. Ra viện:</label
                        >
                        <div
                          class="content-none"
                          v-if="!form.discharge_at"
                        ></div>
                        <span class="fs-16 ml-1">{{
                          getDate(form.discharge_at)
                        }}</span>
                      </div>
                    </div>
                    <!-- discharge_type -->
                    <div class="hodo-print">
                      <div
                        class="flex justify-between items-center pr-0.5 mt-1"
                      >
                        <label for="ravien" class="italic flex items-center">
                          <span class="fs-16">(1.Ra viện</span>
                          <span class="fs-16 ml-4">2.Xin về</span>
                          <span class="fs-16 ml-4">3.Bỏ về</span>
                          <span class="fs-16 ml-4">4.Đưa về )</span>
                        </label>
                        <BoxNumber :str="form.discharge_type" />
                      </div>
                    </div>
                  </div>
                  <div class="hodo-print">
                    <div class="flex justify-between items-center mt-1 pr-0.5">
                      <label class="mr-2 fs-16"
                        >19. Tổng số ngày điều trị:</label
                      >
                      <BoxNumber :str="form.treatment_days" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-0.5">
            <div class="hodo-print relative">
              <div class="font-bold fs-20">III. CHẨN ĐOÁN</div>
              <div
                class="absolute fs-16"
                style="
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  margin-left: -20px;
                "
              >
                Mã
              </div>
            </div>
            <div class="border-box">
              <div class="row pl-1">
                <div class="col-6 hodo-print pr-0">
                  <div class="border-right-item pt-1 pr-0.5">
                    <div
                      :class="
                        !form.diagnose_lowerfacility_name
                          ? 'flex'
                          : 'overflow-hidden'
                      "
                    >
                      <label
                        class="whitespace-nowrap mr-2 fs-16"
                        :class="
                          !form.diagnose_lowerfacility_name ? '' : 'float-left'
                        "
                        >20. Tuyến dưới:</label
                      >
                      <div
                        class="content-none w-full"
                        v-if="!form.diagnose_lowerfacility_name"
                      ></div>
                      <div class="fs-16" v-else>
                        {{ form.diagnose_lowerfacility_name }}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div class="content-none w-full"></div>
                      <BoxNumber :str="form.diagnose_lowerfacility_code" />
                    </div>
                  </div>
                </div>

                <div class="col-6 hodo-print pr-0">
                  <div class="pt-0.5">
                    <div class="flex pr-0.5">
                      <label class="w-22 fs-16">23. Ra viện:</label>
                      <!-- <div
                    class="content-none w-4/5"
                    v-if="!form.discharge_at_diagnose"
                  ></div>
                    <span v-else>{{ form.discharge_at_diagnose }}</span>-->
                    </div>

                    <div
                      class="flex justify-between items-end pt-0.5 pr-2.5 fs-16"
                    >
                      <div
                        class="w-full"
                        :class="
                          !form.disease_main_name ? 'flex' : 'overflow-hidden'
                        "
                      >
                        <label
                          class="whitespace-nowrap mr-2 fs-16"
                          :class="!form.disease_main_name ? '' : 'float-left'"
                          >+ Bệnh chính:</label
                        >
                        <div
                          class="content-none w-full"
                          v-if="!form.disease_main_name"
                        ></div>
                        <span v-else class="fs-16">{{
                          form.disease_main_name
                        }}</span>
                      </div>
                      <BoxNumber class="fs-14" :str="form.disease_main_code" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pl-1">
                <div class="col-6 hodo-print pr-0">
                  <div class="border-right-item pt-0.5 pr-0.5">
                    <div
                      :class="
                        !form.diagnose_emergencydept_name
                          ? 'flex'
                          : 'overflow-hidden'
                      "
                    >
                      <label
                        class="mr-2 whitespace-nowrap fs-16"
                        :class="
                          !form.diagnose_emergencydept_name ? '' : 'float-left'
                        "
                        >21. Cấp cứu KKB:</label
                      >
                      <div
                        class="content-none w-full"
                        v-if="!form.diagnose_emergencydept_name"
                      ></div>
                      <div class="fs-16" v-else>
                        {{ form.diagnose_emergencydept_name }}
                      </div>
                    </div>
                    <div class="flex justify-between fs-14">
                      <div class="content-none w-full"></div>
                      <BoxNumber :str="form.diagnose_emergencydept_code" />
                    </div>
                  </div>
                </div>
                <div class="col-6 flex justify-between pt-0.5">
                  <div class="col-3 hodo-print">
                    <div class="flex items-center fs-16">
                      <label class="mr-2 fs-16">T</label>
                      <BoxNumber :str="form.disease_t_value" />
                    </div>
                  </div>
                  <div class="col-3 hodo-print">
                    <div class="flex justify-center items-center fs-16">
                      <label class="mr-2 fs-16">N</label>
                      <BoxNumber :str="form.disease_n_value" />
                    </div>
                  </div>
                  <div class="col-3 hodo-print">
                    <div class="flex justify-end items-center pr-0.5 fs-16">
                      <label class="mr-2 fs-16">M</label>
                      <BoxNumber :str="form.disease_m_value" />
                    </div>
                  </div>
                  <!-- <div class="col-6 hodo-print">
                  <div class="ml-2 flex pr-0.5 fs-16 justify-between">
                    <label class="mr-2 fs-16">giai đoạn</label>
                    <BoxNumber :str="form.disease_main_code" />
                  </div>
                </div>-->
                </div>
              </div>

              <div class="row pl-1">
                <div class="col-6 hodo-print pr-0">
                  <div class="border-right-item pt-1 pr-0.5">
                    <div
                      :class="
                        !form.diagnose_treatmentdept_name
                          ? 'flex'
                          : 'overflow-hidden'
                      "
                    >
                      <label
                        class="mr-2 whitespace-nowrap fs-16"
                        :class="
                          !form.diagnose_treatmentdept_name ? '' : 'float-left'
                        "
                        >22. Khoa điều trị:</label
                      >
                      <div
                        class="content-none w-full"
                        v-if="!form.diagnose_treatmentdept_name"
                      ></div>
                      <div class="fs-16">
                        {{ form.diagnose_treatmentdept_name }}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div class="content-none w-full"></div>
                      <BoxNumber :str="form.diagnose_treatmentdept_code" />
                    </div>
                  </div>
                </div>
                <div class="col-6 hodo-print pt-0.5 pr-2.5">
                  <div
                    class="w-full"
                    :class="
                      !form.disease_including_name ? 'flex' : 'overflow-hidden'
                    "
                  >
                    <label
                      class="whitespace-nowrap mr-2 fs-16"
                      :class="!form.disease_including_name ? '' : 'float-left'"
                      >+ Bệnh kèm theo:</label
                    >
                    <template v-if="!form.disease_including_name">
                      <div class="content-none w-full"></div>
                    </template>
                    <span class="fs-16" v-else>{{
                      form.disease_including_name
                    }}</span>
                    <div
                      class="flex ml-auto"
                      :class="!form.disease_including_name ? '' : 'float-right'"
                    >
                      <BoxNumber :str="form.disease_including_code" />
                    </div>
                  </div>
                  <div class="flex justify-between pr-0.5 mt-3">
                    <div class="content-none w-full"></div>
                    <!-- <BoxNumber :str="form.disease_including_code" /> -->
                  </div>
                </div>
              </div>

              <div class="row pl-1">
                <div class="col-6 hodo-print pr-0">
                  <div
                    class="flex justify-start whitespace-nowrap border-right-item pt-0.5"
                  >
                    <div class="flex" style="width: 5rem">
                      <div class="content-none w-full"></div>
                      <label class="mx-1 fs-16">T</label>
                      <div
                        class="content-none w-full"
                        v-if="!form.t_value"
                      ></div>
                      <span class="fs-16" v-else>{{ form.t_value }}</span>
                    </div>
                    <div class="flex" style="width: 5rem">
                      <div class="content-none w-full"></div>
                      <label class="mx-1 fs-16">N</label>
                      <div
                        class="content-none w-full"
                        v-if="!form.n_value"
                      ></div>
                      <span class="fs-16">{{ form.n_value }}</span>
                    </div>
                    <div class="flex" style="width: 5rem">
                      <div class="content-none w-full"></div>
                      <label class="mx-1 fs-16">M</label>
                      <div
                        class="content-none w-full"
                        v-if="!form.m_value"
                      ></div>
                      <span class="fs-16">{{ form.m_value }}</span>
                    </div>
                    <div class="flex w-full">
                      <div class="content-none w-full"></div>
                      <label class="mx-1 fs-16">Giai đoạn</label>
                      <div
                        class="content-none w-full"
                        v-if="!form.disease_stage"
                      ></div>
                      <!-- <span class="fs-16">{{ form.disease_stage }}</span> -->
                      <div class="content-none w-full"></div>
                      <BoxNumber
                        class="ml-auto mr-0.5 fs-16"
                        :str="form.disease_stage"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6 hodo-print pr-2.5">
                  <div class="flex justify-between mb-1">
                    <div class="flex pr-2.5">
                      <label class="fs-16">+ Tai biến:</label>
                      <span class="italic ml-1 fs-16">(1. Có</span>
                      <span class="italic ml-3 fs-16">2. Không)</span>
                    </div>
                    <BoxNumber :str="form.is_catastrophe" />
                  </div>
                </div>
              </div>

              <div class="row pl-1 mb-1">
                <!-- <div class="col-6 hodo-print pr-0">
                <div class="flex justify-between items-center border-right-item py-0.5 pr-0.5">
                  <label class="fs-16">+ Tình hình phẫu thuật (1. Có, 2. Không)</label>
                  <BoxNumber :str="form.surgery_status" />
                </div>
              </div>-->
                <div class="col-6 hodo-print pr-0.5 border-right-item">
                  <div class="flex justify-between">
                    <div class="flex pr-2">
                      <label class="fs-16">+ Tình hình thủ thuật:</label>
                      <span class="italic ml-1 fs-16">(1. Có</span>
                      <span class="italic ml-3 fs-16">2. Không)</span>
                    </div>
                    <BoxNumber :str="form.surgery_status" />
                  </div>
                </div>
                <div class="col-6 hodo-print pr-2.5">
                  <div class="flex justify-between">
                    <div class="flex pr-2">
                      <label class="fs-16">+ Biến chứng:</label>
                      <span class="italic ml-1 fs-16">(1. Có</span>
                      <span class="italic ml-3 fs-16">2. Không)</span>
                    </div>
                    <BoxNumber :str="form.is_complications" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-0.5 fs-13">
            <div class="hodo-print">
              <div class="font-bold fs-20">IV. TÌNH TRẠNG RA VIỆN</div>
            </div>

            <div class="border-box fs-13">
              <div class="row pl-1">
                <div class="col-5 border-right-item pt-0.5 pr-0.5">
                  <div class="hodo-print">
                    <label class="fs-16">24. Kết quả điều trị</label>
                  </div>
                  <div class="row flex justify-between items-center pt-0.5">
                    <div class="col-6 hodo-print">
                      <label class="italic flex items-center justify-between">
                        <span class="w-26 fs-16 whitespace-nowrap"
                          >1. Khỏi</span
                        >
                        <input
                          :disabled="!isEdit"
                          type="radio"
                          id="khoi"
                          :value="1"
                          class="mr-2"
                          v-model="form.treatment_results"
                        />
                        <span class="box-item border-box fs-24 ml-1">
                          <span
                            class="fs-24 -mt-1 not-italic"
                            style="line-height: 1"
                          >
                            {{ form.treatment_results === 1 ? "x" : "" }}
                          </span>
                        </span>
                      </label>
                    </div>
                    <div class="col-6 hodo-print">
                      <div class="flex">
                        <label
                          for="nanghon"
                          class="italic flex justify-between mr-2 ml-auto"
                        >
                          <span class="w-24 fs-16 whitespace-nowrap"
                            >4. Nặng hơn</span
                          >
                          <input
                            :disabled="!isEdit"
                            type="radio"
                            id="nanghon"
                            :value="4"
                            class="mr-2"
                            v-model="form.treatment_results"
                          />
                          <span class="box-item border-box fs-24 ml-1">
                            <span
                              class="fs-24 -mt-1 not-italic"
                              style="line-height: 1"
                            >
                              {{ form.treatment_results === 4 ? "x" : "" }}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row flex justify-between items-center pt-0.5">
                    <div class="col-6 hodo-print">
                      <label class="italic flex items-center justify-between">
                        <span class="w-26 fs-16 whitespace-nowrap"
                          >2. Đỡ, giảm</span
                        >
                        <input
                          :disabled="!isEdit"
                          type="radio"
                          id="dogiam"
                          :value="2"
                          class="mr-2"
                          v-model="form.treatment_results"
                        />
                        <span class="box-item border-box fs-24 ml-1">
                          <span
                            class="fs-24 -mt-1 not-italic"
                            style="line-height: 1"
                          >
                            {{ form.treatment_results === 2 ? "x" : "" }}
                          </span>
                        </span>
                      </label>
                    </div>
                    <div class="col-6 hodo-print">
                      <div class="flex">
                        <label
                          for="tuvong"
                          class="italic flex items-center justify-between mr-2 ml-auto"
                        >
                          <span class="w-24 fs-16 whitespace-nowrap"
                            >5. Tử vong</span
                          >
                          <input
                            :disabled="!isEdit"
                            type="radio"
                            id="tuvong"
                            :value="5"
                            class="mr-2"
                            v-model="form.treatment_results"
                          />
                          <span class="box-item border-box fs-24 ml-1">
                            <span
                              class="fs-24 -mt-1 not-italic"
                              style="line-height: 1"
                            >
                              {{ form.treatment_results === 5 ? "x" : "" }}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row flex justify-between items-center pt-0.5">
                    <div class="col-6 hodo-print">
                      <label class="italic flex items-center justify-between">
                        <span class="w-26 fs-16 whitespace-nowrap"
                          >3. Không thay đổi</span
                        >
                        <input
                          :disabled="!isEdit"
                          type="radio"
                          id="khongthaydoi"
                          :value="3"
                          class="mr-2"
                          v-model="form.treatment_result"
                        />
                        <span class="box-item border-box fs-24 ml-1">
                          <span
                            class="fs-24 -mt-1 not-italic"
                            style="line-height: 1"
                          >
                            {{ form.discharge_type === 3 ? "x" : "" }}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-7 hodo-print">
                  <div class="pt-0.5">
                    <div class="flex overflow-hidden">
                      <label class="whitespace-nowrap fs-16"
                        >26. Tình trạng tử vong:</label
                      >
                      <div class="flex">
                        <div class="flex w-28">
                          <div class="content-none w-full"></div>
                          <span class="fs-16">giờ</span>
                        </div>
                        <div class="flex w-28">
                          <div class="content-none w-full"></div>
                          <span class="fs-16">phút</span>
                        </div>
                        <div class="flex w-28">
                          <!-- <div class="content-none w-full"></div> -->
                          <span class="fs-16 ml-auto">ngày</span>
                        </div>
                        <div class="flex w-28">
                          <div class="content-none w-full"></div>
                          <span class="fs-16">tháng</span>
                        </div>
                        <div class="flex w-28">
                          <div class="content-none w-full"></div>
                          <span class="fs-16">năm</span>
                        </div>
                        <div class="content-none"></div>
                      </div>
                    </div>
                    <!-- mortality_status -->
                    <div class="mr-2 justify-between flex mt-2">
                      <label class="italic flex items-center">
                        <span class="fs-16">(1. Do bệnh</span>
                        <span class="fs-16 ml-2">2. Do tai biến điều trị</span>
                        <span class="fs-16 ml-2">3. Khác)</span>
                      </label>
                      <BoxNumber class="fs-14" :str="form.mortality_status" />
                    </div>
                    <div class="flex items-center pt-0.5">
                      <div class="inline-block mr-2">
                        <label
                          for="type13trong24h"
                          class="italic flex items-center"
                        >
                          <span class="w-34 fs-16">1. Trong 24 giờ vào</span>
                          <input
                            :disabled="!isEdit"
                            type="radio"
                            id="type13trong24h"
                            :value="1"
                            class="mr-2"
                            v-model="form.mortality_at"
                          />
                          <span class="box-item border-box fs-24 ml-1">
                            <span
                              class="fs-24 -mt-1 not-italic"
                              style="line-height: 1"
                            >
                              {{ form.mortality_at === 1 ? "x" : "" }}
                            </span>
                          </span>
                        </label>
                      </div>
                      <div class="inline-block ml-auto mr-2">
                        <label
                          for="type13sau24h"
                          class="italic flex items-center"
                        >
                          <span class="w-34 fs-16">2. Sau 24 giờ vào viện</span>
                          <input
                            :disabled="!isEdit"
                            type="radio"
                            id="type13sau24h"
                            :value="2"
                            class="mr-2"
                            v-model="form.mortality_at"
                          />
                          <span class="box-item border-box fs-24 ml-1">
                            <span
                              class="fs-24 -mt-1 not-italic"
                              style="line-height: 1"
                            >
                              {{ form.mortality_at === 2 ? "x" : "" }}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="pr-1">
                      <div
                        class="items-end"
                        :class="
                          !form.mortality_name ? 'flex' : 'overflow-hidden'
                        "
                      >
                        <label
                          class="whitespace-nowrap fs-16 mr-2"
                          :class="
                            !form.mortality_name ? 'mb-0.5' : 'float-left'
                          "
                          >27. Nguyên nhân chính tử vong:</label
                        >
                        <div
                          class="content-none w-full"
                          v-if="!form.mortality_name"
                        ></div>
                        <div class="fs-16" v-else>
                          {{ form.mortality_name }}
                        </div>
                      </div>
                      <div class="flex justify-between">
                        <div class="content-none w-full"></div>
                        <BoxNumber
                          :str="form.mortality_code"
                          class="mr-1 fs-14"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pl-1">
                <div class="col-5 horo-print pr-0">
                  <div class="border-right-item py-0.5 pr-0.5">
                    <div class="-mt-3 justify-between flex">
                      <label class="fs-16"
                        >25. Giải phẫu bệnh (khi có sinh thiết):</label
                      >
                      <BoxNumber
                        class="mr-2 fs-14"
                        :str="form.biopsy_pathology"
                      />
                    </div>
                    <div class="flex justify-between hodo-print">
                      <label for="lanhtinh" class="italic flex items-center">
                        <span class="fs-16">(1. Lành tính</span>
                      </label>
                      <label
                        for="biopsy_pathologynghi"
                        class="italic flex items-center"
                      >
                        <span class="fs-16">2. Nghi ngờ</span>
                      </label>
                      <label for="actinh" class="italic flex items-center">
                        <span class="fs-16">3.Ác tính)</span>
                      </label>
                    </div>
                  </div>
                </div>
                <!-- is_autopsy -->
                <div class="col-7 pt-0.5">
                  <div class="flex justify-between items-center">
                    <div class="flex items-center">
                      <label class="fs-16">28. Khám nghiệm tử thi:</label>
                      <span class="fs-16 ml-2 italic">(1.Có</span>
                      <span class="fs-16 ml-2 italic">2.Không)</span>
                    </div>
                    <BoxNumber :str="form.is_autopsy" class="mr-2 fs-14" />
                  </div>

                  <!-- <div class="flex justify-between items-center py-0.5 pr-0.5">
                  <div class="col-6 hodo-print">
                    <label class="fs-16">28. Khám nghiệm tử thi</label>
                  </div>
                  <div class="flex justify-between items-center w-full py-0.5 pr-0.5 hodo-print">
                    <label for="type15yes" class="italic flex items-center">

                    </label>
                  </div>
                </div>-->
                </div>
              </div>
            </div>
          </div>

          <div class="mt-1 row">
            <div class="col-6 hodo-print text-center">
              <div class="flex justify-center italic">
                <div class="fs-18">
                  Ngày
                  <span>.......</span>
                </div>
                <div class="fs-18">
                  tháng
                  <span>.......</span>
                </div>
                <div class="fs-18">
                  năm
                  <span>.......</span>
                </div>
              </div>
              <div class="fs-18 font-bold">TRƯỞNG PHÒNG KẾ HOẠCH TỔNG HỢP</div>
              <div
                class="fs-22 mt-20 font-bold"
                v-if="form.plan_manager && form.plan_manager.User"
              >
                {{ form.plan_manager.User.name || "" }}
              </div>
              <div v-else class="mt-20 text-left flex">
                <div class="w-24 fs-18 whitespace-nowrap">Họ và tên:</div>
                <div class="content-none w-full"></div>
              </div>
            </div>
            <div class="col-6 hodo-print text-center">
              <div class="flex justify-center italic">
                <div class="fs-18">
                  Ngày
                  <span>.......</span>
                </div>
                <div class="fs-18">
                  tháng
                  <span>.......</span>
                </div>
                <div class="fs-18">
                  năm
                  <span>.......</span>
                </div>
              </div>
              <div class="fs-18 font-bold">TRƯỞNG KHOA</div>
              <div
                class="fs-22 mt-20 font-bold"
                v-if="form.dean && form.dean.User"
              >
                {{ form.dean.User.name || "" }}
              </div>
              <div v-else class="fs-18 mt-20 text-left flex">
                <div class="w-24 fs-18 whitespace-nowrap">Họ và tên:</div>
                <div class="content-none w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="html2pdf__page-break"></div>

      <!-- Page 2 -->
      <div ref="ABenhAn">
        <div class="fontForAll text-black" id="MedicalRecordsViewDetailPage2">
          <div ref="docPage02" id="page-02">
            <div class="hodo-print mt-11">
              <div class="font-bold fs-22">A - BỆNH ÁN</div>
              <div class="overflow-hidden">
                <div class="font-bold whitespace-nowrap float-left mr-1 fs-22">
                  I. Lý do vào viện{{ form.visit_reasons ? ":" : "" }}
                </div>
              </div>
              <div
                v-if="!form.visit_reasons"
                class="content-none w-full mb-2"
              ></div>
              <div
                v-else
                class="box-editor content-size fs-22 mb-2"
                v-html="form.visit_reasons"
              ></div>
            </div>

            <div class>
              <div class="hodo-print">
                <div class="flex flex-row">
                  <div class="font-bold fs-22 whitespace-nowrap">
                    II. Hỏi bệnh
                  </div>
                </div>
              </div>
              <div class="hodo-print">
                <div class="mt-0.5">
                  <div class="overflow-hidden">
                    <div
                      class="font-bold whitespace-nowrap float-left mr-1 fs-22"
                    >
                      1. Quá trình bệnh lý:
                    </div>
                  </div>
                  <div
                    v-if="!form.pathological"
                    class="content-none w-full mb-2"
                  ></div>
                  <div
                    v-else
                    class="box-editor content-size fs-22 mb-2 mt-0.5"
                    v-html="form.pathological"
                  ></div>
                  <!-- <div v-if="!form.pathological" class="content-none w-full my-3"></div> -->
                  <template v-if="!form.pathological">
                    <div class="content-none my-3 pb-1 w-full"></div>
                    <div class="content-none my-3 py-1 w-full"></div>
                    <div class="content-none my-3 py-1 w-full"></div>
                    <div class="content-none my-3 py-1 w-full"></div>
                    <div class="content-none my-3 py-1 w-full"></div>
                  </template>
                </div>
              </div>

              <div class="mt-0.5 fs-16">
                <div class="hodo-print">
                  <label class="font-bold fs-22">2.Tiền sử bệnh:</label>
                  <div>
                    <div
                      class="overflow-hidden mb-2"
                      :class="!form.anamnesis_self ? 'flex' : ''"
                    >
                      <div class="whitespace-nowrap float-left mr-1 fs-22">
                        + Bản thân:
                      </div>
                      <div
                        v-if="!form.anamnesis_self"
                        class="content-none w-full"
                      ></div>
                      <div
                        v-else
                        class="box-editor content-size fs-22 mt-0.5"
                        v-html="form.anamnesis_self"
                      ></div>
                    </div>
                    <div
                      v-if="!form.anamnesis_self"
                      class="content-none w-full my-3"
                    ></div>
                    <!-- <template v-if="!form.anamnesis_self">
                  <div class="content-none my-3 pb-1 w-full"></div>
                  <div class="content-none my-3 py-1 w-full"></div>
                  <div class="content-none my-3 py-1 w-full"></div>
                </template>-->
                  </div>
                  <div>
                    <div
                      class="overflow-hidden mb-2"
                      :class="!form.anamnesis_family ? 'flex' : ''"
                    >
                      <div class="whitespace-nowrap float-left mr-1 fs-22">
                        + Gia đình:
                      </div>
                      <div
                        v-if="!form.anamnesis_family"
                        class="content-none w-full"
                      ></div>
                      <div
                        v-else
                        class="box-editor content-size fs-22 mt-0.5"
                        v-html="form.anamnesis_family"
                      ></div>
                    </div>
                    <div
                      v-if="!form.anamnesis_family"
                      class="content-none w-full my-3"
                    ></div>
                    <!-- <template v-if="!form.anamnesis_family">
                  <div class="content-none my-3 pb-1 w-full"></div>
                  <div class="content-none my-3 py-1 w-full"></div>
                  <div class="content-none my-3 py-1 w-full"></div>
                </template>-->
                  </div>
                </div>

                <div class="hodo-print">
                  <div class="flex">
                    <label class="font-bold fs-22"
                      >3.Đặc điểm liên quan đến bệnh:</label
                    >
                    <span class="fs-18 ml-1 italic">(1.Có</span>
                    <span class="fs-18 ml-3 italic">2.Không)</span>
                  </div>
                </div>

                <div class="mt-0.5 row whitespace-nowrap border-box mx-0.5">
                  <div class="col-6">
                    <div class="row border-bottom-item">
                      <div class="col-1 hodo-print pr-0">
                        <div
                          class="border-right-item p-0 text-center fs-18 pr-1"
                        >
                          TT
                        </div>
                      </div>
                      <div class="col-3 border-right-item"></div>
                      <div class="col-2 hodo-print pr-0">
                        <div class="text-center border-right-item fs-18">
                          Ký hiệu
                        </div>
                      </div>
                      <div class="col-6 hodo-print pr-0">
                        <div class="text-center border-right-item fs-18">
                          Thời gian (tính theo tháng)
                        </div>
                      </div>
                    </div>
                    <div class="row border-bottom-item">
                      <div class="col-1 hodo-print pr-0">
                        <div
                          class="border-right-item flex items-center justify-center fs-18 pr-1"
                        >
                          01
                        </div>
                      </div>
                      <div class="col-3 hodo-print border-right-item">
                        <div class="flex items-center fs-18">- Dị ứng</div>
                      </div>
                      <div class="hodo-print col-2 px-0">
                        <div
                          class="flex justify-center py-0.5 border-right-item fs-18"
                        >
                          <BoxNumber :str="form.disease_related_01_code" />
                        </div>
                      </div>

                      <div class="col-6 hodo-print pr-0 border-right-item">
                        <div class="flex items-center fs-18">
                          {{ form.disease_related_01_time }}
                        </div>
                      </div>
                    </div>
                    <div class="row border-bottom-item">
                      <div class="col-1 hodo-print pr-0">
                        <div
                          class="border-right-item flex items-center justify-center pr-1 fs-18"
                        >
                          02
                        </div>
                      </div>
                      <div class="col-3 hodo-print border-right-item">
                        <div class="flex items-center fs-18">- Ma tuý</div>
                      </div>
                      <div class="hodo-print px-0 col-2">
                        <div
                          class="flex justify-center py-0.5 border-right-item fs-18"
                        >
                          <BoxNumber :str="form.disease_related_02_code" />
                        </div>
                      </div>
                      <div class="col-6 hodo-print border-right-item">
                        <div class="flex items-center fs-18">
                          {{ form.disease_related_02_time }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-1 hodo-print pr-0">
                        <div
                          class="border-right-item flex items-center justify-center pr-1 fs-18"
                        >
                          03
                        </div>
                      </div>
                      <div class="col-3 hodo-print border-right-item">
                        <div class="flex items-center fs-18">- Rượu bia</div>
                      </div>
                      <div class="hodo-print px-0 col-2">
                        <div
                          class="flex justify-center py-0.5 border-right-item fs-18"
                        >
                          <BoxNumber :str="form.disease_related_03_code" />
                        </div>
                      </div>
                      <div class="col-6 hodo-print border-right-item">
                        <div class="flex items-center fs-18">
                          {{ form.disease_related_03_time }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="row border-bottom-item">
                      <div class="col-1 hodo-print pr-0">
                        <div
                          class="border-right-item p-0 text-center fs-18 pr-2"
                        >
                          TT
                        </div>
                      </div>
                      <div class="col-3 border-right-item"></div>
                      <div class="col-2 hodo-print pr-0">
                        <div class="text-center border-right-item fs-18">
                          Ký hiệu
                        </div>
                      </div>
                      <div class="col-6 hodo-print pr-0">
                        <div class="text-center fs-18">
                          Thời gian (tính theo tháng)
                        </div>
                      </div>
                    </div>
                    <div class="row border-bottom-item">
                      <div class="col-1 hodo-print pr-0">
                        <div
                          class="border-right-item flex items-center justify-center pr-1 fs-18"
                        >
                          04
                        </div>
                      </div>
                      <div class="col-3 hodo-print border-right-item">
                        <div class="flex items-center fs-18">- Thuốc lá</div>
                      </div>
                      <div class="hodo-print px-0 col-2">
                        <div
                          class="flex justify-center py-0.5 border-right-item fs-18"
                        >
                          <BoxNumber :str="form.disease_related_04_code" />
                        </div>
                      </div>
                      <div class="col-6 hodo-print">
                        <div class="flex items-center fs-18">
                          {{ form.disease_related_04_time }}
                        </div>
                      </div>
                    </div>
                    <div class="row border-bottom-item">
                      <div class="col-1 hodo-print pr-0">
                        <div
                          class="border-right-item flex items-center justify-center pr-1 fs-18"
                        >
                          05
                        </div>
                      </div>
                      <div class="col-3 hodo-print border-right-item">
                        <div class="flex items-center fs-18">- Thuốc lào</div>
                      </div>
                      <div class="hodo-print px-0 col-2">
                        <div
                          class="flex justify-center py-0.5 border-right-item fs-18"
                        >
                          <BoxNumber :str="form.disease_related_05_code" />
                        </div>
                      </div>
                      <div class="col-6 hodo-print">
                        <div class="flex items-center fs-18">
                          {{ form.disease_related_05_time }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-1 hodo-print pr-0">
                        <div
                          class="border-right-item flex items-center justify-center pr-1 fs-18"
                        >
                          06
                        </div>
                      </div>
                      <div class="col-3 hodo-print border-right-item">
                        <div class="flex items-center fs-18">- Khác</div>
                      </div>
                      <div class="hodo-print px-0 col-2">
                        <div
                          class="flex justify-center py-0.5 border-right-item fs-18"
                        >
                          <BoxNumber :str="form.disease_related_06_code" />
                        </div>
                      </div>
                      <div class="col-6 hodo-print">
                        <div class="flex items-center fs-18">
                          {{ form.disease_related_06_time }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <div class="hodo-print">
                <div class="font-bold fs-22">III. Khám bệnh</div>
              </div>

              <div class="row">
                <div class="hodo-print">
                  <label class="italic">
                    <span class="font-bold not-italic fs-22"
                      >1. Toàn thân:</span
                    >
                    <!-- <span
                  class="fs-14"
                >(ý thức, da niêm mạc, hệ thống hạch, tuyến giáp, vị trí, kích thước, số lượng, di động v.v...)</span>-->
                  </label>
                </div>
                <div class="col-9 hodo-print">
                  <div class="-mr-2 pr-2 mt-3">
                    <template v-if="!form.exam_body">
                      <div class="content-none my-3 pb-1 w-full fs-22"></div>
                      <div class="content-none my-3 py-1 w-full fs-22"></div>
                      <div class="content-none my-3 py-1 w-full fs-22"></div>
                      <div class="content-none my-3 py-1 w-full fs-22"></div>
                    </template>
                    <div
                      class="fontForAll box-editor fs-22"
                      v-html="form.exam_body"
                    ></div>
                  </div>
                </div>

                <div class="col-3 hodo-print">
                  <div class="italic border-box">
                    <div class="flex items-center justify-between p-1">
                      <div class="fs-16">Mạch</div>
                      <div class="text-center fs-16">
                        {{ form.exam_pulse }} (lần/phút)
                      </div>
                    </div>
                    <div class="flex items-center justify-between p-1">
                      <div class="fs-16">Nhiệt độ</div>
                      <div class="fs-16">
                        {{ form.exam_temperature }} (độ C)
                      </div>
                    </div>
                    <div class="flex items-center justify-between p-1">
                      <div class="fs-16">Huyết áp</div>
                      <div class="fs-16">
                        {{ form.exam_blood_pressure }} (mmHg)
                      </div>
                    </div>
                    <div class="flex items-center justify-between p-1">
                      <div class="fs-16">Nhịp thở</div>
                      <div class="fs-16">
                        {{ form.exam_breathing }} (lần/phút)
                      </div>
                    </div>
                    <div class="flex items-center justify-between p-1">
                      <div class="fs-16">Cân nặng</div>
                      <div class="fs-16">{{ form.exam_weight }} (kg)</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3 fs-16">
                <label class="hodo-print">
                  <span class="font-bold fs-22"
                    >2. Khám bộ phận tổn thương:</span
                  >
                </label>
              </div>

              <div class="fs-16 row g-0 mb-3">
                <div class="col-6 hodo-print border-box min-h-350">
                  <div
                    class="text-center italic fs-22 py-1"
                    style="border-bottom: 1px solid black"
                  >
                    Hình vẽ hoặc ảnh
                  </div>
                  <div class="flex flex-col p-1">
                    <div
                      class="mx-0.5 mb-2 pb-1 text-center"
                      v-for="(attachment, index) in attachments"
                      :key="index"
                    >
                      <img
                        @click="onShowMedia(attachments, index)"
                        :class="attachments.length === 1 ? 'h-96' : 'h-40'"
                        v-if="attachment && index <= 4"
                        :src="attachment.url"
                        :alt="`Bộ phận tổn thương ${index + 1}`"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6 hodo-print border-box min-h-350">
                  <div
                    class="text-center italic fs-22 py-1"
                    style="border-bottom: 1px solid black"
                  >
                    Mô tả tổn thương
                  </div>
                  <template v-if="!form.exam_wound_desc">
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                    <div class="content-none p-2 my-2 w-full fs-22"></div>
                  </template>
                  <div
                    class="fontForAll m-3 content-size fs-22"
                    v-html="form.exam_wound_desc"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="html2pdf__page-break"></div>

          <!-- Page 3 -->
          <div ref="docPage03" id="page-03" class="hodo-print">
            <div>
              <div class="overflow-hidden">
                <div class="block mt-11 font-bold fs-22">
                  3. Các cơ quan khác:
                </div>
              </div>

              <div>
                <div
                  class="overflow-hidden mb-2"
                  :class="!form.part_neurological ? 'flex' : ''"
                >
                  <div class="whitespace-nowrap float-left mr-1 fs-22">
                    - Thần Kinh:
                  </div>
                  <div
                    v-if="!form.part_neurological"
                    class="content-none w-full"
                  ></div>
                  <!-- <div class="fs-22" v-else>{{ form.part_neurological }}</div> -->
                  <div
                    v-else
                    class="content-size fs-22"
                    v-html="form.part_neurological"
                  ></div>
                </div>
                <div
                  v-if="!form.part_neurological"
                  class="content-none w-full my-3"
                ></div>
              </div>
              <div>
                <div
                  class="overflow-hidden mb-2"
                  :class="!form.part_circulatory ? 'flex' : ''"
                >
                  <div class="whitespace-nowrap float-left mr-1 fs-22">
                    - Tuần hoàn:
                  </div>
                  <div
                    v-if="!form.part_circulatory"
                    class="content-none w-full"
                  ></div>
                  <!-- <div v-else class="fs-22">{{ form.part_circulatory }}</div> -->
                  <div
                    v-else
                    class="content-size fs-22"
                    v-html="form.part_circulatory"
                  ></div>
                </div>
                <div
                  v-if="!form.part_circulatory"
                  class="content-none w-full my-3"
                ></div>
              </div>
              <div>
                <div
                  class="overflow-hidden mb-2"
                  :class="!form.part_respiratory ? 'flex' : ''"
                >
                  <div class="whitespace-nowrap float-left mr-1 fs-22">
                    - Hô hấp:
                  </div>
                  <div
                    v-if="!form.part_respiratory"
                    class="content-none w-full"
                  ></div>
                  <!-- <div v-else class="fs-22">{{ form.part_respiratory }}</div> -->
                  <div
                    v-else
                    class="content-size fs-22"
                    v-html="form.part_respiratory"
                  ></div>
                </div>
                <div
                  v-if="!form.part_respiratory"
                  class="content-none w-full my-3"
                ></div>
              </div>
              <div>
                <div
                  class="overflow-hidden mb-2"
                  :class="!form.part_digestion ? 'flex' : ''"
                >
                  <div class="whitespace-nowrap float-left mr-1 fs-22">
                    - Tiêu hoá:
                  </div>
                  <div
                    v-if="!form.part_digestion"
                    class="content-none w-full"
                  ></div>
                  <!-- <div v-else class="fs-22">{{ form.part_digestion }}</div> -->
                  <div
                    v-else
                    class="content-size fs-22"
                    v-html="form.part_digestion"
                  ></div>
                </div>
                <div
                  v-if="!form.part_digestion"
                  class="content-none w-full my-3"
                ></div>
              </div>
              <div>
                <div
                  class="overflow-hidden mb-2"
                  :class="!form.part_bone ? 'flex' : ''"
                >
                  <div class="whitespace-nowrap float-left mr-1 fs-22">
                    - Cơ- Xương- Khớp:
                  </div>
                  <div v-if="!form.part_bone" class="content-none w-full"></div>
                  <!-- <div v-else class="fs-22">{{ form.part_bone }}</div> -->
                  <div
                    v-else
                    class="content-size fs-22"
                    v-html="form.part_bone"
                  ></div>
                </div>
                <div
                  v-if="!form.part_bone"
                  class="content-none w-full my-3"
                ></div>
              </div>
              <div>
                <div
                  class="overflow-hidden mb-2"
                  :class="!form.part_urinary ? 'flex' : ''"
                >
                  <div class="whitespace-nowrap float-left mr-1 fs-22">
                    - Tiết niệu:
                  </div>
                  <div
                    v-if="!form.part_urinary"
                    class="content-none w-full"
                  ></div>
                  <!-- <div v-else class="fs-22">{{ form.part_urinary }}</div> -->
                  <div
                    v-else
                    class="content-size fs-22"
                    v-html="form.part_urinary"
                  ></div>
                </div>
                <div
                  v-if="!form.part_urinary"
                  class="content-none w-full my-3"
                ></div>
              </div>
              <div>
                <div
                  class="overflow-hidden mb-2"
                  :class="!form.part_genital ? 'flex' : ''"
                >
                  <div class="whitespace-nowrap float-left mr-1 fs-22">
                    - Sinh dục:
                  </div>
                  <div
                    v-if="!form.part_genital"
                    class="content-none w-full"
                  ></div>
                  <!-- <div v-else class="fs-22">{{ form.part_genital }}</div> -->
                  <div
                    v-else
                    class="content-size fs-22"
                    v-html="form.part_genital"
                  ></div>
                </div>
                <div
                  v-if="!form.part_genital"
                  class="content-none w-full my-3"
                ></div>
              </div>
              <div>
                <div
                  class="overflow-hidden mb-2"
                  :class="!form.part_other ? 'flex' : ''"
                >
                  <div class="whitespace-nowrap float-left mr-1 fs-22">
                    - Khác:
                  </div>
                  <!-- <div v-if="form.part_other" class="fs-22">{{ form.part_other }}</div> -->
                  <div
                    v-if="form.part_other"
                    class="content-size fs-22"
                    v-html="form.part_other"
                  ></div>
                </div>
              </div>
            </div>
            <div :class="!form.laboratory_test ? 'min-h-20' : ''">
              <div>
                <div class="overflow-hidden">
                  <div
                    class="whitespace-nowrap float-left mr-1 font-bold fs-22"
                  >
                    4. Các xét nghiệm cận lâm sàng cần làm:
                  </div>
                </div>
                <div
                  v-if="!form.laboratory_test"
                  class="content-none w-full mt-2"
                ></div>
                <div
                  v-else
                  class="fontForAll box-editor content-size fs-22 mb-2"
                  v-html="form.laboratory_test"
                ></div>
                <template v-if="!form.laboratory_test">
                  <div class="content-none my-3 pb-1 w-full fs-22"></div>
                  <div class="content-none my-3 py-1 w-full fs-22"></div>
                  <div class="content-none my-3 py-1 w-full fs-22"></div>
                  <div class="content-none my-3 py-1 w-full fs-22"></div>
                  <div class="content-none my-3 py-1 w-full fs-22"></div>
                  <div class="content-none my-3 py-1 w-full fs-22"></div>
                  <div class="content-none my-3 py-1 w-full fs-22"></div>
                  <div class="content-none my-3 py-1 w-full fs-22"></div>
                  <div class="content-none my-3 py-1 w-full fs-22"></div>
                  <div class="content-none my-3 py-1 w-full fs-22"></div>
                </template>
              </div>
            </div>
          </div>

          <!-- <div class="html2pdf__page-break"></div> -->

          <!-- Page 4 -->
          <div ref="docPage04" id="page-04">
            <div class="hodo-print">
              <div class="fs-18" :class="!form.exam_summary ? 'min-h-20' : ''">
                <div>
                  <div
                    class="overflow-hidden mb-2"
                    :class="!form.exam_summary ? 'flex' : ''"
                  >
                    <div
                      class="whitespace-nowrap block mt-11 float-left mr-1 font-bold fs-22"
                    >
                      5. Tóm tắt bệnh án:
                    </div>
                  </div>
                  <div
                    v-if="!form.exam_summary"
                    class="content-none w-full mt-2"
                  ></div>
                  <div
                    v-else
                    class="content-size fs-22 box-editor mb-2"
                    v-html="form.exam_summary"
                  ></div>
                  <template v-if="!form.exam_summary">
                    <div class="content-none my-3 pb-1 w-full"></div>
                    <div class="content-none my-3 py-1 w-full"></div>
                    <div class="content-none my-3 py-1 w-full"></div>
                  </template>
                </div>
              </div>

              <div class="font-bold fs-22">
                IV. Chẩn đoán khi vào khoa điều trị:
              </div>

              <div class="mt-0.5 flex justify-between fs-18">
                <div class="w-full">
                  <div
                    class="overflow-hidden mb-2"
                    :class="!form.treat_dept_main_name ? 'flex' : ''"
                  >
                    <div class="whitespace-nowrap float-left mr-1 fs-22">
                      + Bệnh chính:
                    </div>
                    <div
                      v-if="!form.treat_dept_main_name"
                      class="content-none w-full"
                    ></div>
                    <div class="fs-22" v-else>
                      {{ form.treat_dept_main_name }}
                    </div>
                  </div>
                </div>
                <BoxNumber class="fs-22" :str="form.treat_dept_main_code" />
              </div>
            </div>

            <div class="fs-16 row">
              <div class="col-2 hodo-print">
                <div class="flex fs-22">
                  <label class="mr-2 fs-22">T</label>
                  <div
                    class="content-none w-3/4"
                    v-if="!form.treat_dept_t_value"
                  ></div>
                  <div class="fs-22">{{ form.treat_dept_t_value }}</div>
                </div>
              </div>
              <div class="col-2 hodo-print">
                <div class="flex fs-18">
                  <label class="mr-2 fs-22">N</label>
                  <div
                    class="content-none w-3/4"
                    v-if="!form.treat_dept_n_value"
                  ></div>
                  <div class="fs-22">{{ form.treat_dept_n_value }}</div>
                </div>
              </div>
              <div class="col-2 hodo-print">
                <div class="flex fs-22">
                  <label class="mr-2 fs-22">M</label>
                  <div
                    class="content-none w-3/4"
                    v-if="!form.treat_dept_m_value"
                  ></div>
                  <div class="fs-22">{{ form.treat_dept_m_value }}</div>
                </div>
              </div>
              <div class="col-6 hodo-print">
                <div class="flex fs-22">
                  <label class="mr-2 whitespace-nowrap fs-22">Giai đoạn</label>
                  <div
                    class="content-none w-full"
                    v-if="!form.treat_dept_disease_stage"
                  ></div>
                  <!-- <div v-else class="-ml-2 fs-18">{{ form.treat_dept_disease_stage }}</div> -->
                  <BoxNumber
                    class="ml-auto"
                    :str="form.treat_dept_disease_stage"
                  />
                </div>
              </div>
            </div>

            <div class="hodo-print">
              <div class="mt-2 flex justify-between fs-22">
                <div class="w-full">
                  <div
                    class="overflow-hidden mb-2"
                    :class="!form.treat_dept_including_name ? 'flex' : ''"
                  >
                    <div class="whitespace-nowrap float-left mr-1">
                      + Bệnh kèm theo (nếu có):
                    </div>
                    <div
                      v-if="!form.treat_dept_including_name"
                      class="content-none w-full"
                    ></div>
                    <div v-else>{{ form.treat_dept_including_name }}</div>
                  </div>
                </div>
                <BoxNumber :str="form.treat_dept_including_code" />
              </div>

              <div class="mt-0.5 flex justify-between fs-22">
                <div class="w-full">
                  <div
                    class="overflow-hidden mb-2"
                    :class="!form.treat_dept_separate ? 'flex' : ''"
                  >
                    <div class="whitespace-nowrap float-left mr-1 fs-18">
                      + Phân biệt
                    </div>
                    <div
                      v-if="!form.treat_dept_separate"
                      class="content-none w-full"
                    ></div>
                    <div class="fs-18" v-else>
                      {{ form.treat_dept_separate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="fs-22">
                <div
                  class="overflow-hidden"
                  :class="!form.exam_prognosis ? 'flex' : ''"
                >
                  <div
                    class="whitespace-nowrap float-left fs-22 font-bold mr-1"
                  >
                    V. Tiên lượng:
                  </div>
                </div>
                <div
                  v-if="!form.exam_prognosis"
                  class="content-none w-full mt-2"
                ></div>
                <div
                  v-else
                  class="fs-22 content-size box-editor mb-2"
                  v-html="form.exam_prognosis"
                ></div>
                <div
                  v-if="!form.exam_prognosis"
                  class="content-none w-full my-3"
                ></div>
              </div>

              <div class="fs-22">
                <div
                  class="overflow-hidden mb-2"
                  :class="!form.treatment_direction ? 'flex' : ''"
                >
                  <div
                    class="whitespace-nowrap float-left fs-22 font-bold mr-1"
                  >
                    VI. Hướng điều trị:
                  </div>
                </div>
                <div class="mt-0.5 flex justify-between fs-22">
                  <div class="w-full">
                    <div
                      class="overflow-hidden mb-2"
                      :class="!form.treatment_direction ? 'flex' : ''"
                    >
                      <div class="whitespace-nowrap float-left mr-1 fs-22">
                        + Phương pháp chính:
                      </div>
                    </div>
                    <div
                      v-if="!form.treatment_direction"
                      class="content-none w-full"
                    ></div>
                    <div
                      v-else
                      class="box-editor content-size fs-22"
                      v-html="form.treatment_direction"
                    ></div>
                    <div
                      v-if="!form.treatment_direction"
                      class="content-none w-full my-4"
                    ></div>
                    <div
                      v-if="!form.treatment_direction"
                      class="content-none w-full my-3"
                    ></div>
                  </div>
                </div>
                <div class="flex justify-between fs-22">
                  <div class="w-full">
                    <div class="overflow-hidden mb-2 flex fs-22">
                      <div class="whitespace-nowrap float-left mr-1 fs-22">
                        + Chế độ ăn uống bệnh lý: (0.Nhịn ăn; 1.Lỏng; 2.Cháo;
                        3.Cơm; 4.Tự do)
                      </div>
                      <div class="content-none w-full"></div>
                      <BoxNumber :str="form.nutritional_option" />
                    </div>
                  </div>
                </div>
                <div class="flex justify-between fs-22">
                  <div class="w-full">
                    <div class="overflow-hidden mb-2 flex fs-22">
                      <div class="content-none w-full"></div>
                      <div class="whitespace-nowrap float-left mr-1 fs-22">
                        (Kiêng: 1.Muối; 2.Mỡ; 3.Đường; 4.Khác)
                      </div>
                      <div class="content-none w-full"></div>
                      <BoxNumber :str="form.abstinence" />
                    </div>
                  </div>
                </div>
                <div class="flex justify-between fs-22">
                  <div class="w-full">
                    <div class="overflow-hidden mb-2 flex fs-22">
                      <div class="whitespace-nowrap float-left mr-1 fs-22">
                        + Chế độ chăm sóc: (1.Cấp một; 2.Cấp hai; 3.Cấp ba)
                      </div>
                      <div class="content-none w-full"></div>
                      <BoxNumber :str="form.care_option" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hodo-print">
              <div class="fs-22 row mt-16">
                <div class="col-6"></div>
                <div class="col-6">
                  <div class="text-center">
                    <div class="flex justify-center w-full">
                      <div class="fs-22">
                        Ngày
                        <span>.......</span>
                      </div>
                      <div class="fs-22">
                        tháng
                        <span>.......</span>
                      </div>
                      <div class="fs-22">
                        năm
                        <span>.......</span>
                      </div>
                    </div>
                    <div class="font-bold fs-22">Bác sĩ làm bệnh án</div>
                    <div
                      class="fs-22 font-bold mt-20"
                      v-if="form.created_by_doctor_name"
                    >
                      {{ form.created_by_doctor_name }}
                    </div>

                    <div v-else class="mt-20">
                      <div class="flex justify-center overflow-hidden mb-2">
                        <div
                          class="whitespace-nowrap mr-1 fs-22"
                          :class="!form.part_respiratory ? 'float-left' : ''"
                        >
                          Họ và tên:
                        </div>
                        <div class="content-none w-full"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="html2pdf__page-break"></div>

      <!-- Page 5 -->
      <div ref="BTongKet">
        <div
          id="MedicalRecordsViewDetailPage3"
          class="page-05 fontForAll text-black"
        >
          <ViewDetailBTongKet
            :form="form"
            :isEdit="isEdit"
            :medical_record_files="medical_record_files"
            :treat_doctor="treat_doctor"
          />
        </div>
      </div>
    </div>

    <CoolLightBox
      :items="getImagesForCoolight(list_medias)"
      :index="index_media"
      :useZoomBar="true"
      @close="index_media = null"
    ></CoolLightBox>
  </div>
</template>

<script>
import BoxNumber from '../../BoxNumber.vue'
import appUtils from '../../../utils/appUtils'
import ViewDetailBTongKet from './ViewDetailBTongKet.vue'
import { mixinPrint } from '../../../utils/mixinPrint'

export default {
  name: 'MedicalRecordsViewDetail',
  props: [
    'form',
    'person',
    'isEdit',
    'departments',
    'attachments',
    'medical_record_files',
    'treat_doctor',
    'ctaExport',
    'medical_record_code',
    'isOpenPrint',
    'emrEnvelopes'
  ],
  components: { BoxNumber, ViewDetailBTongKet },
  mixins: [mixinPrint],
  data () {
    return {
      index_media: null,
      list_medias: null,
      templateInfo: {
        template_title: 'Bệnh án Ung Bướu'
      }
    }
  },
  watch: {
    ctaExport () {
      console.log('form', this.form)
      this.handleExportPdf()
    }
  },
  created () {
    this.getEmrTemplateConfigs()
  },
  methods: {
    getImagesForCoolight (files) {
      let results = []
      if (files) {
        files.forEach((file) => {
          results.push({
            src: file.url
          })
        })
      }
      return results
    },
    onShowMedia (medias, index) {
      let self = this
      self.list_medias = medias
      self.index_media = index
    },
    formatDate (date) {
      if (!date) return ''
      return window.moment(date).format('YYYY-MM-DD')
    },
    getGender () {
      return appUtils.getGender(this.person.gender)
    },
    getBirthday (date) {
      if (!this.person.birthday) return ' '
      return window.moment(this.person.birthday).format('DDMMYYYY')
    },
    getDate (date) {
      if (!date) return
      const array = window.moment(date).format('HH:mm DD-MM-YYYY')
      const hour = array.split(' ')[0].split(':')
      const date2 = array.split(' ')[1].split('-')
      return `${hour[0]} giờ ${hour[1]} phút, ngày ${date2[0]}/${date2[1]}/${date2[2]}`
    },
    getDate2 (date) {
      if (!date) return
      const array = window.moment(date).format('HH:mm DD/MM/YYYY')
      const hour = array.split(' ')[0].split(':')
      const date2 = array.split(' ')[1]
      return `${hour[0]} giờ ${hour[1]} - ${date2}`
    },
    formatDateView (date) {
      if (!date) return ''
      return window.moment(date).format('DD/MM/YYYY')
    },
    getImageURL (path) {
      return appUtils.getImageURL(path)
    },
    getBase64URL (file) {
      return window.URL.createObjectURL(file)
    },
    async handleExportPdf () {
      try {
        const refContainers = []

        if (!this.emrEnvelopes?.page1?.id) {
          refContainers.push(this.$refs.PatientInfo)
        }

        if (!this.emrEnvelopes?.page2?.id) {
          refContainers.push(this.$refs.ABenhAn)
        }

        if (!this.emrEnvelopes?.page3?.id) {
          refContainers.push(this.$refs.BTongKet)
        }

        if (!refContainers.length) return

        const listUrl = await Promise.all(
          refContainers?.map(async (item, index) => {
            const subFileName =
              index === 0
                ? 'patient_info'
                : index === 1
                  ? 'a_benh_an'
                  : 'b_tong_ket'
            const url = await this.exportPdf(
              {
                refDocument: item,
                fileName: `BenhAnUngBuou_BA_${this.medical_record_code}_${subFileName}`,
                path: 'bavv',
                type: 'bavv',
                type_id: 'bavv_id',
                appendStyle: true
              },
              this.isOpenPrint
            )

            return url
          })
        )

        await this.updateMedicalRecordMaster(listUrl)

        this.$toast.open({
          message: 'Cập nhật bệnh án vào viện thành công',
          type: 'success'
        })
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: 'Có lỗi xảy ra khi lưu bệnh án',
          type: 'error'
        })
      } finally {
        this.$emit('onRefresh')
      }

      // await this.exportPdfCaptureImage({
      //   refDocument: [
      //     this.$refs.docPage01,
      //     this.$refs.docPage02,
      //     this.$refs.docPage03,
      //     this.$refs.docPage04,
      //     this.$refs.docPage05
      //   ],
      //   fileName: 'BenhAnUngBuou_BA_',
      //   path: 'bavv',
      //   type: 'bavv',
      //   type_id: 'bavv_id'
      // }).finally(() => {
      //   this.$emit('setIsLoading', false)
      // })
    },
    async getEmrTemplateConfigs () {
      if (!this.$globalClinic?.id) return

      try {
        const params = {
          ws_id: this.$globalClinic.id
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getEmrTemplateConfigs(params)

        this.templateInfo = response.data || {
          template_title: 'Bệnh án Ung Bướu'
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateMedicalRecordMaster (listUrl) {
      if (!listUrl?.length) return
      const params = {
        patient_info_url: listUrl?.[0],
        a_benh_an_url: listUrl?.[1],
        b_tong_ket_url: listUrl?.[2]
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .putUpdateEMR(this.$route.params.id, params)
    }
  }
}
</script>
<style scoped>
#MedicalRecordsExport {
  width: 100% !important;
}

.fontForAll {
  font-family: "Times New Roman", "Tinos", Times, serif !important;
}

.box-editor /deep/ p {
  margin-bottom: 0;
}
</style>
