<template>
  <div>
    <!-- <ButtonVersion @onShowEMRVersion="handleShowVersion" /> -->
    <div class="text-black" v-if="isEdit">
      <div class="sticky top-0 z-10 flex justify-content-end bg-white text-base font-semibold">
        <button
          class="btn bg-blue-50 border-0 bd-pri txt-pri px-4 mx-3"
          v-if="isEdit"
          @click="close"
        >Hủy</button>
        <button class="btn bg-pri bd-pri text-white px-4" v-if="isEdit" @click="saveFile">Lưu</button>
      </div>
      <HeaderBADT title="Bệnh Án Vào Viện" />
      <div>
        <div class="mb-2">
          <div class="robo-16-500 mb-2">Chọn mẫu Bệnh án vào viện</div>
          <el-select
            allow-create
            clearable
            filterable
            v-model="doc_template_id"
            class="w-full"
            placeholder="Chọn mẫu phiếu"
            @change="handleChangeDocTemplate"
            remote
            :remote-method="getDocsTemplateList"
            :loading="loading"
          >
            <el-option
              v-for="item in docsTemplateList"
              :key="item.id + 'md'"
              :label="handleGetTemLabel(item)"
              :value="item.id"
            />
          </el-select>
        </div>
        <SelectDoctor
          :medicalRecordMaster="medicalRecordMain"
          :emrInfo="getBADT"
          @onChangeDoctor="handleChangeDoctor"
          :emrEnvelopes="emrEnvelopes"
        />
        <div
          :class="
            emrEnvelopes.page1 && emrEnvelopes.page1.id
              ? 'cs_disable_page1'
              : ''
          "
        >
          <div
            v-if="emrEnvelopes.page1 && emrEnvelopes.page1.id"
            class="cs_page1_disabled"
          >{{ $t("Tài liệu đã được ký và không thể cập nhật") }}</div>
          <HanhChinh :form="form" :isEdit="isEdit" />
          <PatientManage
            :form="form"
            :isEdit="isEdit"
            :departments="departments"
            @updateDepartment="departments = $event"
            @updateForm="form = $event"
          />
          <ChanDoan :form="form" :isEdit="isEdit" />
          <TinhTrangRaVien :form="form" :isEdit="isEdit" />
        </div>
        <div
          :class="
            emrEnvelopes.page2 && emrEnvelopes.page2.id
              ? 'cs_disable_page1'
              : ''
          "
        >
          <div
            v-if="emrEnvelopes.page2 && emrEnvelopes.page2.id"
            class="cs_page1_disabled"
          >{{ $t("Tài liệu đã được ký và không thể cập nhật") }}</div>
          <ABenhAn
            :form="form"
            :isEdit="isEdit"
            :attachments="attachments"
            @uploadFile="attachmentsUpdate = $event"
          />
        </div>
        <div
          :class="
            emrEnvelopes.page3 && emrEnvelopes.page3.id
              ? 'cs_disable_page1'
              : ''
          "
        >
          <div
            v-if="emrEnvelopes.page3 && emrEnvelopes.page3.id"
            class="cs_page1_disabled"
          >{{ $t("Tài liệu đã được ký và không thể cập nhật") }}</div>
          <BTongKetBenhAn
            :form="form"
            :isEdit="isEdit"
            :medical_record_files="medical_record_files"
            @uploadDocs="medical_record_files = $event"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="sticky mt-2 top-0 flex justify-content-end bg-white text-base font-semibold"
        style="z-index: 1000"
      >
        <!-- <button
          class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
          @click="openModalSignPDF()"
        >
          <div class="loading">
            <div v-if="loading" class="spinner-border mr-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          
        </button>-->
        <button
          class="btn border-none text-black px-3 mx-2 fs-14"
          @click="handleShowVersion()"
          :disabled="loading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C9.43639 2.00731 6.97349 2.99891 5.12 4.77V3C5.12 2.73478 5.01464 2.48043 4.82711 2.29289C4.63957 2.10536 4.38522 2 4.12 2C3.85478 2 3.60043 2.10536 3.41289 2.29289C3.22536 2.48043 3.12 2.73478 3.12 3V7.5C3.12 7.76522 3.22536 8.01957 3.41289 8.20711C3.60043 8.39464 3.85478 8.5 4.12 8.5H8.62C8.88522 8.5 9.13957 8.39464 9.32711 8.20711C9.51464 8.01957 9.62 7.76522 9.62 7.5C9.62 7.23478 9.51464 6.98043 9.32711 6.79289C9.13957 6.60536 8.88522 6.5 8.62 6.5H6.22C7.50578 5.15636 9.21951 4.30265 11.0665 4.08567C12.9136 3.86868 14.7785 4.30198 16.3407 5.31104C17.9028 6.32011 19.0646 7.84191 19.6263 9.61479C20.188 11.3877 20.1145 13.3009 19.4184 15.0254C18.7223 16.7499 17.4472 18.1781 15.8122 19.0643C14.1772 19.9505 12.2845 20.2394 10.4596 19.8813C8.63463 19.5232 6.99147 18.5405 5.81259 17.1022C4.63372 15.6638 3.99279 13.8597 4 12C4 11.7348 3.89464 11.4804 3.70711 11.2929C3.51957 11.1054 3.26522 11 3 11C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78412 19.2002 6.3459 20.4819 8.17317 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8079 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2ZM12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13H14C14.2652 13 14.5196 12.8946 14.7071 12.7071C14.8946 12.5196 15 12.2652 15 12C15 11.7348 14.8946 11.4804 14.7071 11.2929C14.5196 11.1054 14.2652 11 14 11H13V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z"
              fill="#03267A"
            />
          </svg>
          {{ $t("multidisciplinary_board.lbl_revision_history") }}
        </button>
        <el-dropdown
          v-if="!isShowEmrDetail"
          @command="openModalSignPDF"
          class="flex items-center p-2"
        >
          <span class="el-dropdown-link text-black font-normal">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16">
              <path
                fill="#03267A"
                d="M9.021 1.772a2.445 2.445 0 0 1 3.458 3.457l-.272.271l.086.086a2 2 0 0 1 0 2.828l-1.44 1.44a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-5.646 5.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l6.625-6.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L3.187 9.02l-.925 2.774l2.982-.745l6.527-6.527a1.445 1.445 0 0 0 0-2.043ZM4.967 15c-1.235 0-2.208-.566-2.855-1.107l1.284-.32a3.2 3.2 0 0 0 1.57.427c.406 0 .824-.164 1.27-.457c.447-.293.878-.687 1.314-1.095l.113-.107c.385-.361.784-.737 1.166-.996c.408-.278.95-.53 1.517-.311c.376.144.62.41.791.736c.161.306.272.688.38 1.105c.05.19.133.374.23.498a.46.46 0 0 0 .114.112c.025.015.036.015.039.015c.122 0 .331-.082.63-.286c.185-.126.344-.252.502-.379c.098-.078.197-.157.302-.235c.279-.209.52-.349.697-.438a3.05 3.05 0 0 1 .278-.124l.02-.008l.007-.002l.003-.001h.001l.16.473l-.159-.474a.5.5 0 0 1 .321.947l-.004.002a2.141 2.141 0 0 0-.175.08c-.13.064-.321.174-.55.346c-.059.043-.135.104-.223.174c-.181.143-.409.324-.618.466c-.31.212-.741.46-1.192.46c-.436 0-.751-.265-.946-.517a2.435 2.435 0 0 1-.405-.858c-.11-.423-.196-.7-.296-.89c-.09-.168-.17-.232-.266-.27c-.082-.03-.245-.033-.595.206c-.313.212-.655.533-1.058.912l-.1.093c-.431.405-.92.855-1.449 1.202c-.53.348-1.14.621-1.818.621Z"
              />
            </svg>
            {{ $t("multidisciplinary_board.btn_sign") }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dean" :disabled="loading">
              {{
              $t("Trưởng khoa")
              }}
            </el-dropdown-item>
            <el-dropdown-item command="plan_manager" :disabled="loading">
              {{
              $t("Trưởng phòng kế hoạch tổng hợp")
              }}
            </el-dropdown-item>
            <el-dropdown-item command="emr_doctor" :disabled="loading">
              {{
              $t("Bác sĩ làm bệnh án")
              }}
            </el-dropdown-item>
            <el-dropdown-item command="treatment_doctor" :disabled="loading">
              {{
              $t("Bác sĩ điều trị")
              }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <button
          class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
          @click="showModalSaveDocTemplate(true)"
        >
          <div class="loading">
            <div v-if="loading" class="spinner-border mr-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="copy-icon"
          >
            <path
              d="M16 20H8C7.20435 20 6.44129 19.6839 5.87868 19.1213C5.31607 18.5587 5 17.7956 5 17V7C5 6.73478 4.89464 6.48043 4.70711 6.29289C4.51957 6.10536 4.26522 6 4 6C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7V17C3 18.3261 3.52678 19.5979 4.46447 20.5355C5.40215 21.4732 6.67392 22 8 22H16C16.2652 22 16.5196 21.8946 16.7071 21.7071C16.8946 21.5196 17 21.2652 17 21C17 20.7348 16.8946 20.4804 16.7071 20.2929C16.5196 20.1054 16.2652 20 16 20ZM10 13C10 13.2652 10.1054 13.5196 10.2929 13.7071C10.4804 13.8946 10.7348 14 11 14H16C16.2652 14 16.5196 13.8946 16.7071 13.7071C16.8946 13.5196 17 13.2652 17 13C17 12.7348 16.8946 12.4804 16.7071 12.2929C16.5196 12.1054 16.2652 12 16 12H11C10.7348 12 10.4804 12.1054 10.2929 12.2929C10.1054 12.4804 10 12.7348 10 13ZM21 8.94C20.9896 8.84813 20.9695 8.75763 20.94 8.67V8.58C20.8919 8.47718 20.8278 8.38267 20.75 8.3L14.75 2.3C14.6673 2.22222 14.5728 2.15808 14.47 2.11C14.4402 2.10576 14.4099 2.10576 14.38 2.11C14.2784 2.05174 14.1662 2.01434 14.05 2H10C9.20435 2 8.44129 2.31607 7.87868 2.87868C7.31607 3.44129 7 4.20435 7 5V15C7 15.7956 7.31607 16.5587 7.87868 17.1213C8.44129 17.6839 9.20435 18 10 18H18C18.7956 18 19.5587 17.6839 20.1213 17.1213C20.6839 16.5587 21 15.7956 21 15V9C21 9 21 9 21 8.94ZM15 5.41L17.59 8H16C15.7348 8 15.4804 7.89464 15.2929 7.70711C15.1054 7.51957 15 7.26522 15 7V5.41ZM19 15C19 15.2652 18.8946 15.5196 18.7071 15.7071C18.5196 15.8946 18.2652 16 18 16H10C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15V5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4H13V7C13.0027 7.34114 13.0636 7.67933 13.18 8H11C10.7348 8 10.4804 8.10536 10.2929 8.29289C10.1054 8.48043 10 8.73478 10 9C10 9.26522 10.1054 9.51957 10.2929 9.70711C10.4804 9.89464 10.7348 10 11 10H19V15Z"
              fill="#03267A"
            />
          </svg>
          {{ $t("multidisciplinary_board.btn_save_tem") }}
        </button>

        <button
          class="btn border-none text-black px-3 mx-2"
          @click="handleExportPdf()"
          :disabled="loading"
        >
          <div class="loading" v-if="loading">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8332 4.66667H13.9998V0.5H3.99984V4.66667H3.1665C1.78317 4.66667 0.666504 5.78333 0.666504 7.16667V12.1667H3.99984V15.5H13.9998V12.1667H17.3332V7.16667C17.3332 5.78333 16.2165 4.66667 14.8332 4.66667ZM5.6665 2.16667H12.3332V4.66667H5.6665V2.16667ZM12.3332 13.8333H5.6665V10.5H12.3332V13.8333ZM13.9998 10.5V8.83333H3.99984V10.5H2.33317V7.16667C2.33317 6.70833 2.70817 6.33333 3.1665 6.33333H14.8332C15.2915 6.33333 15.6665 6.70833 15.6665 7.16667V10.5H13.9998Z"
              fill="#20419B"
            />
            <path
              d="M13.9998 8.41667C14.4601 8.41667 14.8332 8.04357 14.8332 7.58333C14.8332 7.1231 14.4601 6.75 13.9998 6.75C13.5396 6.75 13.1665 7.1231 13.1665 7.58333C13.1665 8.04357 13.5396 8.41667 13.9998 8.41667Z"
              fill="#20419B"
            />
          </svg>
          {{ $t("multidisciplinary_board.lbl_print") }}
        </button>
        <button class="btn border-none text-black px-3" @click="editForm(true)" :disabled="loading">
          <div class="loading" v-if="loading">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.6201 9.00044C16.4212 9.00044 16.2304 9.07946 16.0898 9.22011C15.9491 9.36076 15.8701 9.55153 15.8701 9.75044V14.2504C15.8701 14.4494 15.7911 14.6401 15.6504 14.7808C15.5098 14.9214 15.319 15.0004 15.1201 15.0004H4.62012C4.4212 15.0004 4.23044 14.9214 4.08979 14.7808C3.94913 14.6401 3.87012 14.4494 3.87012 14.2504V3.75044C3.87012 3.55153 3.94913 3.36076 4.08979 3.22011C4.23044 3.07946 4.4212 3.00044 4.62012 3.00044H9.12012C9.31903 3.00044 9.50979 2.92142 9.65045 2.78077C9.7911 2.64012 9.87012 2.44935 9.87012 2.25044C9.87012 2.05153 9.7911 1.86076 9.65045 1.72011C9.50979 1.57946 9.31903 1.50044 9.12012 1.50044H4.62012C4.02338 1.50044 3.45108 1.7375 3.02913 2.15945C2.60717 2.58141 2.37012 3.15371 2.37012 3.75044V14.2504C2.37012 14.8472 2.60717 15.4195 3.02913 15.8414C3.45108 16.2634 4.02338 16.5004 4.62012 16.5004H15.1201C15.7169 16.5004 16.2892 16.2634 16.7111 15.8414C17.1331 15.4195 17.3701 14.8472 17.3701 14.2504V9.75044C17.3701 9.55153 17.2911 9.36076 17.1504 9.22011C17.0098 9.07946 16.819 9.00044 16.6201 9.00044ZM5.37012 9.57044V12.7504C5.37012 12.9494 5.44914 13.1401 5.58979 13.2808C5.73044 13.4214 5.9212 13.5004 6.12012 13.5004H9.30012C9.39882 13.501 9.49667 13.4821 9.58805 13.4448C9.67942 13.4074 9.76254 13.3525 9.83262 13.2829L15.0226 8.08544L17.1526 6.00044C17.2229 5.93072 17.2787 5.84777 17.3168 5.75638C17.3549 5.66498 17.3745 5.56695 17.3745 5.46794C17.3745 5.36893 17.3549 5.2709 17.3168 5.17951C17.2787 5.08812 17.2229 5.00516 17.1526 4.93544L13.9726 1.71794C13.9029 1.64765 13.8199 1.59185 13.7285 1.55377C13.6372 1.5157 13.5391 1.49609 13.4401 1.49609C13.3411 1.49609 13.2431 1.5157 13.1517 1.55377C13.0603 1.59185 12.9773 1.64765 12.9076 1.71794L10.7926 3.84044L5.58762 9.03794C5.51811 9.10802 5.46311 9.19113 5.42579 9.28251C5.38847 9.37389 5.36955 9.47174 5.37012 9.57044ZM13.4401 3.30794L15.5626 5.43044L14.4976 6.49544L12.3751 4.37294L13.4401 3.30794ZM6.87012 9.87794L11.3176 5.43044L13.4401 7.55294L8.99262 12.0004H6.87012V9.87794Z"
              fill="#03267A"
            />
          </svg>
          {{ $t("multidisciplinary_board.lbl_modify_short") }}
        </button>
      </div>

      <div v-if="!isShowEmrDetail">
        <ContainerEmrPDFViewer
          :key="refreshContainer"
          ref="ContainerEmrPDFViewer"
          :refreshContainer="refreshContainer"
          :medicalRecordMain="medicalRecordMain"
          :isLoading="loading"
          :emrEnvelopes="emrEnvelopes"
        />
      </div>
    </div>
    <ModalSaveDocumentTemplate
      ref="ModalSaveDocumentTemplate"
      :doc-content="docContent"
      :doc-type="MENU_EMR_TEMPLATE[0].value"
    />
    <div v-show="isShowEmrDetail" class="size-export" ref="viewDetail">
      <ViewDetail
        :form="form"
        :isEdit="isEdit"
        :departments="departments"
        :attachments="attachments"
        :medical_record_files="medical_record_files"
        :person="person"
        :treat_doctor="treat_doctor"
        :ctaExport="ctaExport"
        :medical_record_code="medical_record_code"
        :isOpenPrint="isOpenPrint"
        :emrEnvelopes="emrEnvelopes"
        @setIsLoading="setIsLoading"
        @onRefresh="handleRefresh"
      />
    </div>
    <ModalSignPDF
      ref="BAVV_ModalSignPDF"
      containerIDProps="BAVV_ContainerSignPDFID"
      elementIDProps="BAVV_SignPDFID"
      imageIDProps="BAVV_SignPDFImageID"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
  </div>
</template>

<script>
import _, { cloneDeep, debounce } from 'lodash'
import ABenhAn from './ABenhAn.vue'
import BTongKetBenhAn from './BTongKetBenhAn.vue'
import ChanDoan from './ChanDoan.vue'
import HanhChinh from './HanhChinh.vue'
import PatientManage from './PatientManage.vue'
import TinhTrangRaVien from './TinhTrangRaVien.vue'
import ViewDetail from './ViewDetail.vue'
import HeaderBADT from '../Header'
import { mapState } from 'vuex'
import { mixinPrint } from '../../../utils/mixinPrint'
import appUtils from '../../../utils/appUtils'
import {
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE,
  MENU_EMR_TEMPLATE
} from '../../../utils/constants'
import ModalSaveDocumentTemplate from '../ModalSaveDocumentTemplate.vue'
import ButtonVersion from '@/components/ElectronicMedicalRecords/EMRVersion/ButtonVersion.vue'
import SelectDoctor from './SelectDoctor.vue'
import ContainerEmrPDFViewer from './ContainerEmrPDFViewer.vue'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'
import uploadS3File from '../../../utils/uploadS3File'

// import html2pdf from 'html2pdf.js'

const setFormDefault = (value, person) => {
  const newValue = cloneDeep(value)

  return {
    ...newValue,
    visit_reasons: newValue.visit_reasons || '',
    pathological: newValue.pathological || '',
    anamnesis_self: newValue.anamnesis_self || '',
    anamnesis_family: newValue.anamnesis_family || '',
    exam_body: newValue.exam_body || '',
    exam_wound_desc: newValue.exam_wound_desc || '',
    laboratory_test: newValue.laboratory_test || '',
    exam_summary: newValue.exam_summary || '',
    exam_prognosis: newValue.exam_prognosis || '',
    treatment_direction: newValue.treatment_direction || '',
    clinical_progress: newValue.clinical_progress || '',
    laboratory_test_summary: newValue.laboratory_test_summary || '',
    discharge_status: newValue.discharge_status || '',
    treatment_direction_next: newValue.treatment_direction_next || '',
    insurance_number: person?.insurance_number || '',
    insurance_start_date: person?.insurance_start_date || '',
    insurance_expire: person?.insurance_expire || '',
    person
  }
}

export default {
  name: 'TreatmentCard',
  mixins: [mixinPrint, mixinSignPdf],
  components: {
    HeaderBADT,
    ABenhAn,
    BTongKetBenhAn,
    ChanDoan,
    HanhChinh,
    PatientManage,
    TinhTrangRaVien,
    ViewDetail,
    ModalSaveDocumentTemplate,
    ButtonVersion,
    SelectDoctor,
    ContainerEmrPDFViewer,
    ModalSignPDF
  },
  props: [
    'medicalRecordMain',
    'medical_record_code',
    'treat_doctor',
    'getBADT'
  ],
  data () {
    return {
      form: {},
      isEdit: false,
      ctaExport: 0,
      departments: [
        {
          department:
            this.$globalClinic?.abbr_name || this.$globalClinic?.name || null,
          start_time: null,
          days: null
        },
        {
          department: null,
          start_time: null,
          days: null
        }
      ],
      attachments: [],
      attachmentsUpdate: [],
      medical_record_files: [
        {
          type: 'X - quang',
          count: ''
        },
        {
          type: 'CT Scanner',
          count: ''
        },
        {
          type: 'Siêu âm',
          count: ''
        },
        {
          type: 'Xét nghiệm',
          count: ''
        },
        {
          type: 'Khác',
          count: ''
        }
      ],
      loading: false,
      statuses: [
        { status: 0, name: 'Đang chờ' },
        { status: 1, name: 'Đón tiếp' },
        { status: 2, name: 'Khám bệnh' },
        { status: 3, name: 'Điều trị' },
        { status: 4, name: 'Chuyển khoa/Ra viện' }
      ],
      docsTemplateList: [],
      doc_template_id: null,
      MENU_EMR_TEMPLATE,
      isOpenPrint: false,
      isPrinting: false,
      refreshContainer: 0,
      emrEnvelopes: {
        page1: null,
        page2: null,
        page3: null,
        isProcessed: false
      },
      paramsUpdateEmr: {
        page1: [
          'dean_id',
          'plan_manager_id',
          'career_name',
          'career_code',
          'ethnic_name',
          'ethnic_code',
          'nationality_name',
          'nationality_code',
          'address_detail',
          'address_wards_name',
          'insurance_type',
          'address_district_name',
          'address_district_code',
          'insurance_number',
          'address_province_name',
          'address_province_code',
          'insurance_expire',
          'work_at',
          'emergency_contact',
          'archive_number',
          'admission_hospital_number',
          'admission_department_number',
          'admission_room',
          'admission_bed',
          'reception_at',
          'reception_count',
          'reception_location',
          'reception_from',
          'inouts',
          'transfer_hospital',
          'transfer_to',
          'discharge_at',
          'discharge_type',
          'treatment_days',
          'diagnose_lowerfacility_name',
          'diagnose_lowerfacility_code',
          'diagnose_emergencydept_name',
          'diagnose_emergencydept_code',
          'diagnose_treatmentdept_name',
          'diagnose_treatmentdept_code',
          't_value',
          'n_value',
          'm_value',
          'disease_stage',
          'disease_main_name',
          'disease_main_code',
          'disease_t_value',
          'disease_n_value',
          'disease_m_value',
          'disease_including_name',
          'disease_including_code',
          'surgery_status',
          'is_catastrophe',
          'is_complications',
          'treatment_results',
          'mortality_status',
          'mortality_at',
          'biopsy_pathology',
          'mortality_name',
          'mortality_code',
          'is_autopsy'
        ],
        page2: [
          'visit_reasons',
          'pathological',
          'anamnesis_self',
          'anamnesis_family',
          'disease_related_01_code',
          'disease_related_01_time',
          'disease_related_02_code',
          'disease_related_02_time',
          'disease_related_03_code',
          'disease_related_03_time',
          'disease_related_04_code',
          'disease_related_04_time',
          'disease_related_05_code',
          'disease_related_05_time',
          'disease_related_06_code',
          'disease_related_06_time',
          'exam_body',
          'exam_pulse',
          'exam_temperature',
          'exam_blood_pressure',
          'exam_breathing',
          'exam_weight',
          'exam_wound_desc',
          'attachments',
          'part_neurological',
          'part_circulatory',
          'part_respiratory',
          'part_digestion',
          'part_bone',
          'part_urinary',
          'part_genital',
          'part_other',
          'laboratory_test',
          'exam_summary',
          'treat_dept_main_name',
          'treat_dept_main_code',
          'treat_dept_t_value',
          'treat_dept_n_value',
          'treat_dept_m_value',
          'treat_dept_disease_stage',
          'treat_dept_including_name',
          'treat_dept_including_code',
          'treat_dept_separate',
          'exam_prognosis',
          'treatment_direction',
          'nutritional_option',
          'abstinence',
          'care_option',
          'created_by_doctor_name',
          'emr_doctor_id'
        ],
        page3: [
          'treatment_doctor_id',
          'clinical_progress',
          'laboratory_test_summary',
          'tx_opt',
          'tx_rad_preoperative',
          'tx_rad_preoperative_ganglion',
          'tx_rad_pure',
          'tx_rad_pure_ganglion',
          'tx_surgery',
          'tx_rad_postoperative',
          'tx_rad_postoperative_ganglion',
          'tx_chemicals',
          'batch_number',
          'tx_meet',
          'tx_other',
          'discharge_status',
          'treatment_direction_next',
          'files'
        ]
      }
    }
  },
  watch: {
    medicalRecordMain: {
      deep: true,
      handler () {
        this.mapData()
        if (this.isPrinting) return
        this.handleDebounce()
      }
    },
    getBADT (data) {
      if (data?.created_at && !this.departments[0].start_time) {
        this.departments[0].start_time = data.created_at
      }
    }
  },
  computed: {
    ...mapState({
      person: state => {
        return state.person
      }
    }),
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.getBADT)
    },
    docContent () {
      return {
        visit_reasons: {
          label: 'A.BỆNH ÁN/I.LÝ DO VÀO VIỆN',
          value: this.form?.visit_reasons,
          editor: 1
        },
        pathological: {
          label: 'A.BỆNH ÁN/II.1. Quá trình bệnh lý',
          value: this.form?.pathological,
          editor: 1
        },
        anamnesis_self: {
          label: 'A.BỆNH ÁN/II.2.Tiền sử bệnh bản thân',
          value: this.form?.anamnesis_self,
          editor: 1
        },
        anamnesis_family: {
          label: 'A.BỆNH ÁN/II.2.Tiền sử bệnh gia đình',
          value: this.form?.anamnesis_family,
          editor: 1
        },
        exam_body: {
          label: 'A.BỆNH ÁN/III.1.Toàn thân',
          value: this.form?.exam_body,
          editor: 1
        },
        exam_wound_desc: {
          label: 'A.BỆNH ÁN/III.2.Khám bộ phận tổn thương',
          value: this.form?.exam_wound_desc,
          editor: 1
        },
        part_neurological: {
          label: 'A.BỆNH ÁN/III.3.Thần kinh',
          value: this.form?.part_neurological,
          editor: 1
        },
        part_circulatory: {
          label: 'A.BỆNH ÁN/III.3.Tuần hoàn',
          value: this.form?.part_circulatory,
          editor: 1
        },
        part_respiratory: {
          label: 'A.BỆNH ÁN/III.3.Hô hấp',
          value: this.form?.part_respiratory,
          editor: 1
        },
        part_digestion: {
          label: 'A.BỆNH ÁN/III.3.Tiêu hóa',
          value: this.form?.part_digestion,
          editor: 1
        },
        part_bone: {
          label: 'A.BỆNH ÁN/III.3.Cơ xương khớp',
          value: this.form?.part_bone,
          editor: 1
        },
        part_urinary: {
          label: 'A.BỆNH ÁN/III.3.Tiết niệu',
          value: this.form?.part_urinary,
          editor: 1
        },
        part_genital: {
          label: 'A.BỆNH ÁN/III.3.Sinh dục',
          value: this.form?.part_genital,
          editor: 1
        },
        part_other: {
          label: 'A.BỆNH ÁN/III.3.Khác',
          value: this.form?.part_other,
          editor: 1
        },
        laboratory_test: {
          label: 'A.BỆNH ÁN/III.4.Các xét nghiệm cận lâm sàng cần làm',
          value: this.form?.laboratory_test,
          editor: 1
        },
        exam_summary: {
          label: 'A.BỆNH ÁN/III.5.Tóm tắt bệnh án',
          value: this.form?.exam_summary,
          editor: 1
        },
        exam_prognosis: {
          label: 'A.BỆNH ÁN/V. Tiên lượng',
          value: this.form?.exam_prognosis,
          editor: 1
        },
        treatment_direction: {
          label: 'A.BỆNH ÁN/VI. Hướng điều trị',
          value: this.form?.treatment_direction,
          editor: 1
        },
        clinical_progress: {
          label:
            'B - TỔNG KẾT BỆNH ÁN/1.Quá trình bệnh lý và diễn biến lâm sàng',
          value: this.form?.clinical_progress,
          editor: 1
        },
        laboratory_test_summary: {
          label:
            'B - TỔNG KẾT BỆNH ÁN/2. Tóm tắt kết quả xét nghiệm cận lâm sàng có giá trị chẩn đoán',
          value: this.form?.laboratory_test_summary,
          editor: 1
        },
        discharge_status: {
          label: 'B - TỔNG KẾT BỆNH ÁN/4. Tình trạng người bệnh ra viện',
          value: this.form?.discharge_status,
          editor: 1
        },
        treatment_direction_next: {
          label:
            'B - TỔNG KẾT BỆNH ÁN/5. Hướng điều trị và các chế độ tiếp theo',
          value: this.form?.treatment_direction_next,
          editor: 1
        }
      }
    },
    isShowEmrDetail () {
      return (
        !this.medicalRecordMain?.patient_info_url ||
        !this.medicalRecordMain?.a_benh_an_url ||
        !this.medicalRecordMain?.b_tong_ket_url
      )
    }
  },

  mounted () {
    this.getDocsTemplateList()
    this.handleGetEMREnvelops()
  },
  methods: {
    mapData () {
      if (this.medicalRecordMain) {
        this.form = setFormDefault(this.medicalRecordMain, this.person)

        if (!this.form.admission_hospital_number) {
          this.form.admission_hospital_number = this.getBADT?.ref_id
        }
      } else {
        this.form = setFormDefault({}, this.person)
        this.form.admission_hospital_number = this.getBADT?.ref_id
      }

      this.form.insurance_start_date = this.form?.insurance_start_date
        ? this.formatDate(this.form.insurance_start_date)
        : ''
      this.form.insurance_expire = this.form?.insurance_expire
        ? this.formatDate(this.form.insurance_expire)
        : ''
      this.form.reception_at = this.form?.reception_at
        ? this.formatDate(this.form.reception_at)
        : this.form?.person?.admission_hospital_date
          ? this.formatDate(this.form?.person?.admission_hospital_date)
          : ''
      this.form.discharge_at = this.form?.discharge_at
        ? this.formatDate(this.form.discharge_at)
        : ''
      this.form.discharge_at_diagnose = this.form?.discharge_at_diagnose
        ? this.formatDate(this.form.discharge_at_diagnose)
        : ''
      this.form.profile_delivered_at = this.form?.profile_delivered_at
        ? window.moment(this.form?.profile_delivered_at).format('YYYY-MM-DD')
        : ''
      this.form.profile_received_at = this.form?.profile_received_at
        ? window.moment(this.form?.profile_received_at).format('YYYY-MM-DD')
        : ''

      if (this.form?.inouts && this.form?.inouts.length > 0) {
        if (this.form?.inouts.length > 1) {
          this.departments = this.form?.inouts
        } else {
          this.departments = [
            ...this.form?.inouts,
            {
              department: '',
              start_time: '',
              days: null
            }
          ]
        }
      }

      _.forEach(this.departments, value => {
        if (value.start_time) {
          value.start_time = this.formatDate(value.start_time)
        }
      })

      this.attachments = this.form?.attachments || []

      if (this.form?.files && this.form?.files.length > 0) {
        this.medical_record_files = this.form?.files
      }
    },
    saveFile () {
      this.postBADTMasters()
      // this.editForm(false)
    },
    close () {
      // this.isShowEmrDetail = true
      this.editForm(false)
      this.mapData()
    },
    async postBADTMasters () {
      const id = this.$route.params.id
      if (!id) {
        alert('BADT không tồn tại!')
        return
      }

      const params = await this.handleProcessParamsUpdate()

      try {
        await this.$rf
          .getRequest('DoctorRequest')
          .putUpdateEMR(this.$route.params.id, params)
        this.loading = true
        this.isEdit = false
        this.isOpenPrint = false
        this.isPrinting = true

        this.$emit('refresh', () => {
          this.$nextTick(() => {
            this.ctaExport++
          })
        })
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: 'Cập nhật bệnh án vào viện thất bại',
          type: 'error'
        })
      } finally {
        this.doc_template_id = null
      }
    },
    async handleProcessParamsUpdate () {
      const id = this.$route.params.id

      // let params={}

      const params = {
        medical_record_id: id,
        ...this.form,
        discharge_at: this.form?.discharge_at
          ? appUtils.formatInputDateTime(this.form?.discharge_at)
          : '',
        reception_at: this.form?.reception_at
          ? appUtils.formatInputDateTime(this.form?.reception_at)
          : '',
        discharge_at_diagnose: this.form?.discharge_at_diagnose
          ? appUtils.formatInputDateTime(this.form?.discharge_at_diagnose)
          : '',
        treatment_days: Number(this.form?.treatment_days) || 0,
        inouts: this.departments?.map(item => ({
          ...item,
          days: Number(item?.days) || null,
          start_time: item?.start_time
            ? window
              .moment(item.start_time)
              .toDate()
              .toISOString()
            : null
        })),
        files: this.medical_record_files?.map(item => ({
          ...item,
          count: Number(item?.count) || null
        })),
        insurance_type: Number(this.form?.insurance_type) || null
      }

      const attacchmentResult = await this.handleUploadFiles(this.attachments)

      params.attachments = attacchmentResult

      if (this.emrEnvelopes.page1?.id) {
        appUtils.deleteItemsFromObject(params, this.paramsUpdateEmr.page1)
      }
      if (this.emrEnvelopes.page2?.id) {
        appUtils.deleteItemsFromObject(params, this.paramsUpdateEmr.page2)
      }
      if (this.emrEnvelopes.page3?.id) {
        appUtils.deleteItemsFromObject(params, this.paramsUpdateEmr.page3)
      }

      return params
    },
    formatDate (date) {
      if (!date) return ''
      return window.moment(date).format('YYYY-MM-DD HH:mm')
    },
    editForm (status) {
      this.isEdit = status
    },
    setIsLoading (value) {
      this.loading = value
    },
    async handleExportPdf () {
      const page1Url =
        this.emrEnvelopes.page1?.document_url ||
        this.medicalRecordMain?.patient_info_url
      const page2Url =
        this.emrEnvelopes.page2?.document_url ||
        this.medicalRecordMain?.a_benh_an_url
      const page3Url =
        this.emrEnvelopes.page3?.document_url ||
        this.medicalRecordMain?.b_tong_ket_url

      if (!page1Url && !page2Url && !page3Url) {
        await this.exportPdf({
          refDocument: this.$refs.viewDetail,
          fileName: `BenhAnUngBuou_BA_${this.medical_record_code}_`,
          path: 'bavv',
          type: 'bavv',
          type_id: 'bavv_id',
          appendStyle: true
        }).finally(() => {
          this.loading = false
        })
        return
      }

      if (this.emrEnvelopes.page1?.document_url) {
        const s3Url = await uploadS3File.getLinkVideoAWS(
          this.emrEnvelopes.page1?.document_url
        )
        window.open(s3Url, '_blank')
        // window.open(page1Url, '_blank')
      } else {
        window.open(this.medicalRecordMain?.patient_info_url, '_blank')
      }

      if (this.emrEnvelopes.page2?.document_url) {
        const s3Url = await uploadS3File.getLinkVideoAWS(
          this.emrEnvelopes.page2?.document_url
        )
        window.open(s3Url, '_blank')
        // window.open(page1Url, '_blank')
      } else {
        window.open(this.medicalRecordMain?.a_benh_an_url, '_blank')
      }

      if (this.emrEnvelopes.page3?.document_url) {
        const s3Url = await uploadS3File.getLinkVideoAWS(
          this.emrEnvelopes.page3?.document_url
        )
        window.open(s3Url, '_blank')
        // window.open(page1Url, '_blank')
      } else {
        window.open(this.medicalRecordMain?.b_tong_ket_url, '_blank')
      }

      // this.loading = true
      // this.isOpenPrint = true
      // this.ctaExport++

      // await this.exportPdf({
      //   refDocument: this.$refs.viewDetail,
      //   fileName: `BenhAnUngBuou_BA_${this.medical_record_code}_`,
      //   path: 'bavv',
      //   type: 'bavv',
      //   type_id: 'bavv_id',
      //   appendStyle: true
      // }).finally(() => {
      //   this.loading = false
      // })
    },
    getStatusMR (status) {
      if (status === undefined || status === null) return ''
      return this.statuses[status].name
    },
    async getDocsTemplateList (search) {
      try {
        this.loading = true
        const params = {
          workspace_id: this.$globalClinic.id,
          limit: 1000,
          sort_by: 'desc',
          type: Number(MENU_EMR_TEMPLATE[0].value)
        }
        if (search) {
          params.keyword = search
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getDocsTemplateList(params)
          .then(r => {
            const responseData = r.data?.data?.data || []
            this.docsTemplateList = responseData.filter(
              item =>
                item?.author_id === this.$user?.id ||
                item?.emr_temp_name?.is_public === 1
            )
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleChangeDocTemplate (selectedDoc) {
      const docTemplateInfo =
        this.docsTemplateList.find(item => item.id === selectedDoc) || {}
      const contentObj = docTemplateInfo.content
        ? JSON.parse(docTemplateInfo.content)
        : {}

      for (const [key, value] of Object.entries(contentObj)) {
        contentObj[key] = value?.value
      }

      this.form = {
        ...this.form,
        ...contentObj
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    },
    showModalSaveDocTemplate (show) {
      this.$refs.ModalSaveDocumentTemplate.showModalSaveDocTemplate(show)
      // window.$('#modalSaveDocTemplate-1').modal(show ? 'show' : 'hide')
    },
    handleShowVersion () {
      const params = {
        medical_record_id: this.$route.params.id,
        page_num: 1,
        page_size: 10000,
        order: 'desc',
        sort_by: 'created_at'
      }
      this.$store.dispatch('emrStore/getEMRVersion', params)
      this.$eventBus.$emit('onShowEMRVersion', true)
    },
    async handleUploadFiles (data) {
      if (!data?.length) return []

      try {
        const fileUrls = await Promise.allSettled(
          data?.map(async file => {
            if (file?.id) {
              return { url: file.url, name: file.name }
            }

            const params = {
              file,
              FolderTarget: 'emr_master',
              IdTarget: this.$route.params.id
            }
            const formData = new FormData()
            for (const key in params) {
              formData.append(key, params[key])
            }

            const res = await this.$rf
              .getRequest('DoctorRequest')
              .uploadFileTenant(formData)
            return { url: res.data.fileUrl, name: file.name }
          })
        )

        return fileUrls
          ?.filter(item => item.status === 'fulfilled')
          .map(itemMap => itemMap?.value)
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    },
    handleChangeDoctor (doctorInfo, key) {
      this.form[key] = doctorInfo.id

      if (key === 'emr_doctor_id') {
        this.form.created_by_doctor_name = doctorInfo?.name
      }
    },
    handleRefresh () {
      this.loading = false
      this.isPrinting = false
      this.$emit('refresh', () => {
        this.refreshContainer++
        // this.$forceUpdate()
      })
    },
    async openModalSignPDF (role) {
      let error

      const { documentLink, documentType } = this.getDocumentInfo(role)
      const documentID = this.medicalRecordMain?.id

      const signRef = 'BAVV_ModalSignPDF'

      const envelopeInfo = await this.handleCheckSignEnvelope(
        documentType,
        documentID,
        signRef,
        role
      )

      if (envelopeInfo) return

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })
        return
      }

      const procedure = await this.handleProcessProcedureData(
        err => {
          if (err) {
            error = true

            this.$toast.open({
              message: err,
              type: 'error'
            })
          }
        },
        documentType,
        false
      )

      if (error) return

      const signer = procedure?.signatories?.find(
        item => item?.user_role === role
      )?.user

      if (signer?.id !== this.$user?.id) {
        this.$toast.open({
          message: 'Bạn không có quyền ký tài liệu này',
          type: 'error'
        })
        return
      }

      this.$nextTick(() => {
        this.$refs[signRef].handleOpen(
          documentLink,
          documentType,
          documentID,
          signer?.id,
          procedure?.signatories || [],
          null,
          procedure?.id,
          false
        )
      })
    },
    getUserByRole (role, callback) {
      let user
      switch (role) {
        case 'dean':
          const dean = this.medicalRecordMain?.dean?.User
          if (!dean) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Trưởng khoa')
          } else {
            user = dean
          }
          break

        case 'plan_manager':
          const plan_manager = this.medicalRecordMain?.plan_manager?.User
          if (!plan_manager) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Trưởng phòng kế hoạch tổng hợp')
          } else {
            user = plan_manager
          }
          break

        case 'emr_doctor':
          const emr_doctor = this.medicalRecordMain?.emr_doctor?.User
          if (!emr_doctor) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Bác sĩ làm bệnh án')
          } else {
            user = emr_doctor
          }
          break

        case 'treatment_doctor':
          const treatment_doctor = this.medicalRecordMain?.treatment_doctor
            ?.User
          if (!treatment_doctor) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Bác sĩ làm bệnh án')
          } else {
            user = treatment_doctor
          }
          break
        default:
          break
      }

      return user
    },
    async handleSignDocumentSuccess () {
      this.handleGetEMREnvelops()
      this.refreshContainer++
      // window.$('#modalViewPYLT').modal('hide')
    },
    getDocumentInfo (role) {
      let documentLink
      let documentType

      switch (role) {
        case 'dean':
          documentLink = this.medicalRecordMain?.patient_info_url
          documentType =
            ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_PATIENT_INFO
          break
        case 'plan_manager':
          documentLink = this.medicalRecordMain?.patient_info_url
          documentType =
            ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_PATIENT_INFO
          break
        case 'emr_doctor':
          documentLink = this.medicalRecordMain?.a_benh_an_url
          documentType = ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_A_BENH_AN
          break
        case 'treatment_doctor':
          documentLink = this.medicalRecordMain?.b_tong_ket_url
          documentType = ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_B_TONG_KET
          break

        default:
          break
      }

      return { documentLink, documentType }
    },
    async handleCheckSignEnvelope (
      docType,
      docID,
      ModalSignPDFRef,
      selectedRole
    ) {
      try {
        const signEnvelope = await this.handleGetEnvelopeByDocumentID(
          docID,
          docType
        )

        if (!signEnvelope?.id) return

        const signatories = signEnvelope?.envelope_signatories?.map(item => ({
          ...item,
          user: item?.user_info,
          user_id: item?.user_id,
          sign_node_id: item?.node_signatory_info?.sign_node_id
        }))

        const signerInfo = signEnvelope?.envelope_signatories?.find(
          item => item?.node_signatory_info?.user_role === selectedRole
        )

        const s3Url = await uploadS3File.getLinkVideoAWS(
          signEnvelope?.document_url
        )

        if (signerInfo?.status === 2) {
          this.$toast.open({
            message: 'Bạn đã ký tài liệu này',
            type: 'error'
          })

          return signEnvelope
        } else {
          const hasPermissionSignatory = signerInfo?.user_id === this.$user?.id

          if (!hasPermissionSignatory) {
            this.$toast.open({
              message: 'Bạn không có quyền ký tài liệu này',
              type: 'error'
            })

            return signEnvelope
          }

          this.$nextTick(() => {
            this.$refs[ModalSignPDFRef].handleOpen(
              s3Url,
              docType,
              Number(docID),
              signerInfo?.user_id,
              signatories || [],
              signEnvelope,
              false
            )
          })
        }

        return signEnvelope
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetEMREnvelops () {
      try {
        if (this.isShowEmrDetail) return

        this.loading = true
        this.emrEnvelopes.isProcessed = false
        const documentID = this.medicalRecordMain?.id

        if (!documentID) return

        await Promise.all(
          [
            ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_PATIENT_INFO,
            ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_A_BENH_AN,
            ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_B_TONG_KET
          ].map(async docType => {
            const envelopeInfo = await this.handleGetEnvelopeByDocumentID(
              documentID,
              docType
            )

            switch (docType) {
              case ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_PATIENT_INFO:
                this.emrEnvelopes.page1 = envelopeInfo
                break
              case ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_A_BENH_AN:
                this.emrEnvelopes.page2 = envelopeInfo
                break
              case ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_B_TONG_KET:
                this.emrEnvelopes.page3 = envelopeInfo
                break

              default:
                break
            }
          })
        )
      } catch (error) {
        console.log(error)
      } finally {
        this.emrEnvelopes.isProcessed = true
        this.loading = false
      }
    },
    handleDebounce: debounce(function () {
      this.handleGetEMREnvelops()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.h-detail2 {
  height: calc(100vh - 11rem);
}

.ck-editor__editable {
  min-height: 50px !important;
}

.size-export {
  width: 892px !important;
  margin: auto;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.cs_disable_page1 {
  pointer-events: none;
  position: relative;
  padding: 20px;
  margin-top: 20px;

  /* Create overlay after*/
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(122 122 122 / 29%);
    z-index: 9;
    padding: 20px;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.cs_page1_disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fab868c8;
  padding: 20px;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: bold;
}
</style>
