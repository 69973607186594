<template>
  <div class="mt-6" v-if="formData">
    <div class="text-2xl font-semibold">II. QUẢN LÝ NGƯỜI BỆNH</div>
    <div class="row mt-3">
      <div class="col-md-6">
        <label class="fs-14 txt-grey-900">Số vào viện</label>
        <input
          v-model="formData.admission_hospital_number"
          :readonly="!isEdit"
          class="form--input txt-grey-900"
        />
      </div>
      <div class="col-md-6">
        <label class="fs-14 txt-grey-900">Số vào khoa</label>
        <input
          v-model="formData.admission_department_number"
          :readonly="!isEdit"
          class="form--input txt-grey-900"
        />
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-6">
        <label class="fs-14 txt-grey-900">Phòng</label>
        <input
          v-model="formData.admission_room"
          :readonly="!isEdit"
          class="form--input txt-grey-900"
        />
      </div>
      <div class="col-md-6">
        <label class="fs-14 txt-grey-900">Giường</label>
        <input
          v-model="formData.admission_bed"
          :readonly="!isEdit"
          class="form--input txt-grey-900"
        />
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-6">
        <label class="fs-14 txt-grey-900">12. Vào viện</label>
        <el-date-picker
          v-model="reception_at"
          type="datetime"
          placeholder="Chọn ngày giờ"
          @change="handleChangeReceptionAt"
        ></el-date-picker>
      </div>
      <div class="col-md-6">
        <label class="fs-14 txt-grey-900">Vào viện lần thứ</label>
        <input
          v-model="formData.reception_count"
          :readonly="!isEdit"
          type="text"
          class="form--input txt-grey-900"
        />
      </div>
    </div>

    <div class="mt-3 row">
      <div class="col-md-6">
        <div>
          <label class="fs-14 txt-grey-900">13. Trực tiếp tại</label>
        </div>
        <div class="flex mt-1.5">
          <label for="capcuu" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="capcuu"
              :value="1"
              class="mr-2"
              name="reception_location"
              v-model="formData.reception_location"
            />1.Cấp cứu
          </label>
          <label for="KKB" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="KKB"
              :value="2"
              class="mr-2"
              name="reception_location"
              v-model="formData.reception_location"
            />2.KKB
          </label>
          <label for="khoadieutri" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="khoadieutri"
              :value="3"
              class="mr-2"
              name="reception_location"
              v-model="formData.reception_location"
            />3.Khoa điều
            trị
          </label>
          <label for="reset" class="d-flex align-items-center">
            <button
              @click="formData.reception_location = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <label class="fs-14 txt-grey-900">14. Nơi giới thiệu</label>
        </div>
        <div class="flex mt-1.5">
          <label for="coquanyte" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="coquanyte"
              :value="1"
              class="mr-2"
              name="reception_from"
              v-model="formData.reception_from"
            />1.Cơ quan Y
            tế
          </label>
          <label for="tuden" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="tuden"
              :value="2"
              class="mr-2"
              name="reception_from"
              v-model="formData.reception_from"
            />2. Tự đến
          </label>
          <label for="reset" class="d-flex align-items-center">
            <button
              @click="formData.reception_from = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>
    </div>

    <div class="mt-3 row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-3 fs-14 txt-grey-900">Khoa</div>
          <div class="col-md-3 fs-14 txt-grey-900">Ngày</div>
          <div class="col-md-3 fs-14 txt-grey-900">Số ngày ĐT</div>
        </div>
        <div
          class="row mt-3 items-center"
          v-for="(department, index) in departmentsChild"
          :key="index"
        >
          <div class="col-md-3 fs-14 txt-grey-900">
            {{index === 0 ? '15. Vào khoa' : index === 1 ? '16. Chuyển khoa' :
            ''}}
          </div>
          <div class="col-md-3 fs-14 txt-grey-900">
            <input
              v-model="department.department"
              @blur="updateDepartment"
              :readonly="!isEdit"
              type="text"
              class="form--input txt-grey-900"
            />
          </div>
          <div class="col-md-3 fs-14 txt-grey-900">
            <el-date-picker
              v-model="department.start_time"
              type="datetime"
              placeholder="Chọn ngày giờ"
            ></el-date-picker>
          </div>
          <div class="col-md-3 fs-14 txt-grey-900 flex items-center">
            <input
              v-model="department.days"
              @blur="updateDepartment"
              type="text"
              :readonly="!isEdit"
              class="form--input txt-grey-900"
            />
            <div class="h-7 w-7">
              <div v-if="department.delete" @click="deleteDepartment(index)">
                <Delete class="cursor-pointer" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-3 cursor-pointer flex justify-center items-center font-semibold"
          v-show="isEdit"
        >
          <div
            class="cursor-pointer rounded-md text-primary hover:bg-blue-50 transition-all ease-linear px-3 py-2"
            @click="addDepartment"
          >
            Thêm
            khoa
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <div>
            <label for="nam" class="fs-14 txt-grey-900">17. Chuyển viện</label>
          </div>
          <div class="flex mt-1.5">
            <label for="tuyentren" class="d-flex align-items-center mr-3 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="tuyentren"
                :value="1"
                class="mr-2"
                v-model="formData.transfer_hospital"
              />1.Tuyến
              trên
            </label>
            <label for="tuyenduoi" class="d-flex align-items-center mr-3 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="tuyenduoi"
                :value="2"
                class="mr-2"
                v-model="formData.transfer_hospital"
              />2.Tuyến
              dưới
            </label>
            <label for="CK" class="d-flex align-items-center mr-3 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="CK"
                :value="3"
                class="mr-2"
                v-model="formData.transfer_hospital"
              />3.CK
            </label>
            <label class="d-flex align-items-center">
              <button
                @click="formData.transfer_hospital = null"
                class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
              >Chọn lại</button>
            </label>
          </div>
        </div>
        <div class="mt-3">
          <label class="fs-14 txt-grey-900">Chuyển đến</label>
          <input
            v-model="formData.transfer_to"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="mt-3">
          <label class="fs-14 txt-grey-900">18. Ra viện</label>
          <el-date-picker
            v-model="discharge_at"
            type="datetime"
            placeholder="Chọn ngày giờ"
            @change="handleChangeDischargeAt"
          ></el-date-picker>
          <div class="flex mt-3">
            <label for="ravien" class="d-flex align-items-center mr-3 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="ravien"
                :value="1"
                class="mr-2"
                v-model="formData.discharge_type"
              />1.Ra viện
            </label>
            <label for="tuyenduoi6" class="d-flex align-items-center mr-3 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="tuyenduoi6"
                :value="2"
                class="mr-2"
                v-model="formData.discharge_type"
              />2.Xin
              về
            </label>
            <label for="bove" class="d-flex align-items-center mr-3 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="bove"
                :value="3"
                class="mr-2"
                v-model="formData.discharge_type"
              />3.Bỏ về
            </label>
            <label for="duave" class="d-flex align-items-center mr-3 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="duave"
                :value="4"
                class="mr-2"
                v-model="formData.discharge_type"
              />4.Đưa về
            </label>
            <label class="d-flex align-items-center">
              <button
                @click="formData.discharge_type = null"
                class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
              >Chọn lại</button>
            </label>
          </div>
        </div>
        <div class="mt-3 flex items-center">
          <label class="fs-14 txt-grey-900 whitespace-nowrap mr-2">19. Tổng số ngày điều trị</label>
          <input
            v-model="formData.treatment_days"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Delete } from '@/components/Icon'
export default {
  name: 'PatientManage',
  props: ['form', 'isEdit', 'departments'],
  components: { Delete },
  data () {
    return {
      formData: this.form || null,
      departmentsChild: [],
      reception_at: '',
      discharge_at: ''
    }
  },
  watch: {
    departments (array) {
      if (array && array.length > 0) this.departmentsChild = array
    }
  },
  mounted () {
    this.formData = this.form
    this.reception_at = this.form.reception_at
    this.discharge_at = this.form.discharge_at

    if (this.departments && this.departments.length > 0) {
      this.departmentsChild = this.departments
    } else {
      this.departmentsChild = [
        {
          department: undefined,
          start_time: undefined,
          days: undefined
        },
        {
          department: undefined,
          start_time: undefined,
          days: undefined
        }
      ]
    }
  },
  methods: {
    addDepartment () {
      const temp = {
        department: undefined,
        start_time: undefined,
        days: undefined,
        delete: true
      }
      this.departmentsChild.push(temp)
    },
    deleteDepartment (index) {
      this.departmentsChild.splice(index, 1)
    },
    handleChangeReceptionAt (value) {
      this.reception_at = value
      this.formData = {
        ...this.formData,
        reception_at: this.reception_at
      }
      this.updateForm()
    },
    handleChangeDischargeAt (value) {
      this.discharge_at = value
      this.formData = {
        ...this.formData,
        discharge_at: this.discharge_at
      }
      this.updateForm()
    },
    updateDepartment () {
      this.$emit('updateDepartment', this.departmentsChild)
    },
    updateForm () {
      this.$emit('updateForm', this.formData)
    }
  }
}
</script>
