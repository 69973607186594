<template>
  <div>
    <div class="flex gap-2 items-center justify-center">
      <div class="flex-1">
        <label class="font-bold">{{ $t("Bác sĩ làm bệnh án") }}</label>
        <el-select
          class="w-full mt-2"
          :disabled="isLoading || hasSignEnvelope.page2"
          v-model="form.emr_doctor_id"
          placeholder
          @change="(item) => handleChangeDoctorType(item, 'emr_doctor_id')"
        >
          <el-option v-for="item in doctors" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="flex-1">
        <label class="font-bold">{{ $t("Bác sĩ điều trị") }}</label>
        <el-select
          class="w-full mt-2"
          :disabled="isLoading || hasSignEnvelope.page3"
          v-model="form.treatment_doctor_id"
          placeholder
          @change="
            (item) => handleChangeDoctorType(item, 'treatment_doctor_id')
          "
        >
          <el-option v-for="item in doctors" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
    </div>
    <div class="flex gap-2 items-center justify-center mt-2">
      <div class="flex-1">
        <label class="font-bold">{{ $t("Trưởng khoa") }}</label>
        <el-select
          class="w-full mt-2"
          :disabled="isLoading || hasSignEnvelope.page1"
          v-model="form.dean_id"
          placeholder
          @change="(item) => handleChangeDoctorType(item, 'dean_id')"
        >
          <el-option v-for="item in doctors" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="flex-1">
        <label class="font-bold">{{ $t("Trưởng phòng kế hoạch và tổng hợp") }}</label>
        <el-select
          class="w-full mt-2"
          :disabled="isLoading || hasSignEnvelope.page1"
          v-model="form.plan_manager_id"
          placeholder
          @change="(item) => handleChangeDoctorType(item, 'plan_manager_id')"
        >
          <el-option v-for="item in doctors" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SelectDoctor',
  props: {
    medicalRecordMaster: {
      type: Object
    },
    emrEnvelopes: Object
  },
  data () {
    return {
      isLoading: false,
      doctors: [],
      form: {
        emr_doctor_id: null,
        treatment_doctor_id: null,
        dean_id: null,
        plan_manager_id: null
      }
    }
  },
  watch: {
    medicalRecordMaster: {
      deep: true,
      handler (data) {
        this.handleMapData(data)
      }
    }
  },
  computed: {
    ...mapState({
      emrData: state => {
        return state.emrData
      }
    }),
    hasSignEnvelope () {
      return {
        page1: Boolean(this.emrEnvelopes?.page1?.id),
        page2: Boolean(this.emrEnvelopes?.page2?.id),
        page3: Boolean(this.emrEnvelopes?.page3?.id)
      }
    }
  },
  async created () {
    await this.getDoctors()
    this.handleMapData(this.medicalRecordMaster)
  },
  methods: {
    handleChangeDoctorType (doctorID, key) {
      if (!doctorID) return

      if (key === 'emr_doctor_id' && this.hasSignEnvelope.page2) {
        return
      }

      if (key === 'treatment_doctor_id' && this.hasSignEnvelope.page3) {
        return
      }

      if (
        (key === 'dean_id' || key === 'plan_manager_id') &&
        this.hasSignEnvelope.page1
      ) {
        return
      }

      this.form[key] = doctorID

      const doctorInfo = this.doctors?.find(item => item?.id === doctorID)

      this.$emit('onChangeDoctor', doctorInfo, key)
    },
    async getDoctors () {
      try {
        const clinic_id = this.$globalClinic?.id
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
        this.doctors = res.data?.data
      } catch (error) {
        console.log(error)
      }
    },
    handleMapData (data) {
      this.form.emr_doctor_id = data?.emr_doctor_id || null
      this.form.dean_id = data?.dean_id || null
      this.form.plan_manager_id = data?.plan_manager_id || null

      if (data?.treatment_doctor_id) {
        this.form.treatment_doctor_id = data?.treatment_doctor_id
      } else {
        this.form.treatment_doctor_id = this.emrData?.treat_doctor?.id
        this.handleChangeDoctorType(
          this.emrData?.treat_doctor?.id,
          'treatment_doctor_id'
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>