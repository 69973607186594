<template>
  <div class="mt-6 fs-14">
    <div class="hodo-print mt-11">
      <div class="font-bold fs-22">B - TỔNG KẾT BỆNH ÁN</div>
    </div>
    <div class="border-box border-b-0 px-1">
      <div class="hodo-print">
        <div class="font-bold fs-22">
          1. Quá trình bệnh lý và diễn biến lâm sàng:
        </div>
        <div :class="!form.clinical_progress ? 'min-h-20' : ''">
          <template v-if="!form.clinical_progress">
            <div class="content-none my-3 pb-1 w-full"></div>
            <div class="content-none my-3 py-1 w-full"></div>
            <div class="content-none my-3 py-1 w-full"></div>
            <div class="content-none my-3 py-1 w-full"></div>
            <div class="content-none my-3 py-1 w-full"></div>
          </template>
          <div
            class="content-size fs-22 box-editor"
            v-html="form.clinical_progress"
          ></div>
        </div>
        <div class="font-bold fs-22 mt-2.5">
          2. Tóm tắt kết quả xét nghiệm cận lâm sàng có giá trị chẩn đoán:
        </div>
        <div :class="!form.laboratory_test_summary ? 'min-h-12' : ''">
          <template v-if="!form.laboratory_test_summary">
            <div class="content-none my-3 pb-1 w-full"></div>
            <div class="content-none my-3 py-1 w-full"></div>
            <div class="content-none my-3 py-1 w-full"></div>
          </template>
          <div
            class="content-size fs-22 box-editor"
            v-html="form.laboratory_test_summary"
          ></div>
        </div>
      </div>
      <div>
        <div class="hodo-print">
          <div class="font-bold fs-22 mt-2.5">3. Phương pháp điều trị:</div>
        </div>
        <div class="flex justify-around mt-2 hodo-print">
          <div class="fs-22">
            <label for="type11" class="flex items-center mr-4">
              <span class="mr-1 fs-22">1. Điều trị triệt để</span>
              <input
                :disabled="!isEdit"
                type="radio"
                id="type11"
                :value="1"
                class="mr-2"
                v-model="form.tx_opt"
              />
              <span class="box-item border-box fs-24 ml-1">
                <span class="fs-24 -mt-1 not-italic" style="line-height: 1">{{
                  form.tx_opt === 1 ? "x" : ""
                }}</span>
              </span>
            </label>
          </div>
          <div class="fs-22">
            <label for="type12" class="flex items-center mr-4">
              <span class="mr-1 fs-22">2. Điều trị triệu chứng</span>
              <input
                type="radio"
                :disabled="!isEdit"
                id="type12"
                :value="2"
                class="mr-2"
                v-model="form.tx_opt"
              />
              <span class="box-item border-box fs-24 ml-1">
                <span class="fs-24 -mt-1 not-italic" style="line-height: 1">{{
                  form.tx_opt === 2 ? "x" : ""
                }}</span>
              </span>
            </label>
          </div>
        </div>

        <div>
          <div class="row mt-2.5">
            <div class="col-6 hodo-print">
              <div class="flex w-full fs-22">
                <label class="whitespace-nowrap mr-1 fs-22"
                  >- Tia xạ tiền phẫu:</label
                >
                <div
                  class="content-none w-full"
                  v-if="!form.tx_rad_preoperative"
                ></div>
                <span class="fs-22" v-else>{{ form.tx_rad_preoperative }}</span>
              </div>
            </div>

            <div class="col-6 hodo-print">
              <div class="flex w-full fs-22">
                <label class="whitespace-nowrap mr-1 fs-22">Tại hạch:</label>
                <div
                  class="content-none w-full"
                  v-if="!form.tx_rad_preoperative_ganglion"
                ></div>
                <span class="fs-22" v-else>{{
                  form.tx_rad_preoperative_ganglion
                }}</span>
              </div>
            </div>
          </div>

          <div class="row mt-2.5">
            <div class="col-6 hodo-print">
              <div class="flex w-full fs-22">
                <label class="whitespace-nowrap mr-1 fs-22"
                  >- Tia xạ đơn thuần:</label
                >
                <div class="content-none w-full" v-if="!form.tx_rad_pure"></div>
                <span class="fs-22" v-else>{{ form.tx_rad_pure }}</span>
              </div>
            </div>

            <div class="col-6 hodo-print">
              <div class="flex w-full fs-22">
                <label class="whitespace-nowrap mr-1 fs-22">Tại hạch:</label>
                <div
                  class="content-none w-full"
                  v-if="!form.tx_rad_pure_ganglion"
                ></div>
                <span class="fs-22" v-else>{{
                  form.tx_rad_pure_ganglion
                }}</span>
              </div>
            </div>
          </div>

          <div class="row mt-2.5">
            <div class="col-12 hodo-print">
              <div class="flex w-full fs-22">
                <label class="whitespace-nowrap mr-1 fs-22"
                  >- Phẫu thuật (tên):</label
                >
                <div class="content-none w-full" v-if="!form.tx_surgery"></div>
                <span class="fs-22" v-else>{{ form.tx_surgery }}</span>
              </div>
            </div>
          </div>

          <div class="row mt-2.5">
            <div class="col-6 hodo-print">
              <div class="flex w-full fs-22">
                <label class="whitespace-nowrap mr-1 fs-22"
                  >- Tia xạ hậu phẫu:</label
                >
                <div
                  class="content-none w-full"
                  v-if="!form.tx_rad_postoperative"
                ></div>
                <span class="fs-22" v-else>{{
                  form.tx_rad_postoperative
                }}</span>
              </div>
            </div>

            <div class="col-6 hodo-print">
              <div class="flex w-full fs-22">
                <label class="whitespace-nowrap mr-1 fs-22">Tại hạch:</label>
                <div
                  class="content-none w-full"
                  v-if="!form.tx_rad_postoperative_ganglion"
                ></div>
                <span class="fs-22" v-else>{{
                  form.tx_rad_postoperative_ganglion
                }}</span>
              </div>
            </div>
          </div>

          <div class="row mt-2.5">
            <div class="col-12 hodo-print">
              <div class="flex w-full mb-2 fs-22">
                <label class="whitespace-nowrap mr-1 fs-22"
                  >- Hoá chất (phác đồ):</label
                >
                <div
                  class="content-none w-full"
                  v-if="!form.tx_chemicals"
                ></div>
                <span class="fs-22" v-else>{{ form.tx_chemicals }}</span>
              </div>
            </div>
            <div class="col-12 hodo-print">
              <div class="flex w-full mb-2 fs-22">
                <label class="whitespace-nowrap ml-2 mr-1 fs-22">Số đợt:</label>
                <div
                  class="content-none w-full"
                  v-if="!form.batch_number"
                ></div>
                <span class="fs-22" v-else>{{ form.batch_number }}</span>
              </div>
            </div>
            <div class="col-12 hodo-print">
              <div class="flex items-center justify-between fs-22">
                <label class="whitespace-nowrap ml-2 mr-1 fs-22"
                  >Đáp ứng:</label
                >
                <label for="type2no" class="d-flex align-items-center mr-4">
                  <span class="fs-22">1. Không đáp ứng</span>
                  <input
                    :disabled="!isEdit"
                    type="radio"
                    id="type2no"
                    :value="1"
                    class="mr-2"
                    v-model="form.tx_meet"
                  />
                  <span class="box-item border-box fs-24 ml-1">
                    <span class="fs-24 -mt-1 not-italic" style="line-height: 1">
                      {{ form.tx_meet === 1 ? "x" : "" }}
                    </span>
                  </span>
                </label>
                <label
                  for="tx_meetpart"
                  class="d-flex align-items-center mr-4 fs-22"
                >
                  <span class="fs-22">2. Bán phần</span>
                  <input
                    :disabled="!isEdit"
                    type="radio"
                    id="tx_meetpart"
                    :value="2"
                    class="mr-2"
                    v-model="form.tx_meet"
                  />
                  <span class="box-item border-box fs-24 ml-1">
                    <span class="fs-24 -mt-1 not-italic" style="line-height: 1">
                      {{ form.tx_meet === 2 ? "x" : "" }}
                    </span>
                  </span>
                </label>
                <label
                  for="tx_meetyes"
                  class="d-flex align-items-center mr-4 fs-22"
                >
                  <span class="fs-22">3. Hoàn toàn</span>
                  <input
                    :disabled="!isEdit"
                    type="radio"
                    id="tx_meetyes"
                    :value="3"
                    class="mr-2"
                    v-model="form.tx_meet"
                  />
                  <span class="box-item border-box fs-24 ml-1">
                    <span class="fs-24 -mt-1 not-italic" style="line-height: 1">
                      {{ form.tx_meet === 3 ? "x" : "" }}
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="row mt-2.5">
            <div class="col-12 hodo-print">
              <div class="flex w-full fs-22">
                <label class="whitespace-nowrap mr-1 fs-22"
                  >- Điều trị khác:</label
                >
                <div class="content-none w-full" v-if="!form.tx_other"></div>
                <span class="fs-22" v-else v-html="form.tx_other"></span>
              </div>
              <div v-if="!form.tx_other" class="content-none w-full my-3"></div>
            </div>
          </div>
        </div>

        <div class="row mt-0.5">
          <div class="col-12 hodo-print">
            <div class="fs-22">
              <div
                class="overflow-hidden mb-2"
                :class="!form.discharge_status ? 'flex' : ''"
              >
                <div class="font-bold whitespace-nowrap float-left mr-1 fs-22">
                  4. Tình trạng người bệnh ra viện:
                </div>
                <div
                  v-if="!form.discharge_status"
                  class="content-none w-full"
                ></div>
                <div
                  v-else
                  class="content-size fs-22 box-editor"
                  v-html="form.discharge_status"
                ></div>
              </div>
              <!-- <div v-if="!form.discharge_status" class="content-none w-full my-3"></div> -->
              <template v-if="!form.discharge_status">
                <div class="content-none my-3 pb-1 w-full"></div>
                <div class="content-none my-3 py-1 w-full"></div>
              </template>
            </div>
          </div>
        </div>

        <div class="row mt-0.5">
          <div class="col-12 hodo-print">
            <div class="fs-22">
              <div
                class="overflow-hidden mb-2"
                :class="!form.treatment_direction_next ? 'flex' : ''"
              >
                <div class="font-bold whitespace-nowrap float-left mr-1 fs-22">
                  5. Hướng điều trị và các chế độ tiếp theo:
                </div>
                <div
                  v-if="!form.treatment_direction_next"
                  class="content-none w-full"
                ></div>
                <div
                  v-else
                  class="content-size fs-22 box-editor"
                  v-html="form.treatment_direction_next"
                ></div>
              </div>
              <!-- <div v-if="!form.treatment_direction_next" class="content-none w-full my-3"></div> -->
              <template v-if="!form.treatment_direction_next">
                <div class="content-none my-3 pb-1 w-full"></div>
                <div class="content-none my-3 py-1 w-full"></div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="m-0 border-box row">
      <div class="col-4 border-right border-dark">
        <div class="row text-center">
          <div class="hodo-print px-0">
            <div class="font-bold text-center border-dark border-bottom fs-22">
              Hồ sơ, phim, ảnh
            </div>
          </div>
          <div class="col-8 hodo-print">
            <span class="font-bold fs-22">Loại</span>
          </div>
          <div class="col-4 hodo-print border-left border-dark">
            <span class="font-bold fs-22">Số tờ</span>
          </div>
        </div>
        <div
          class="row"
          v-for="(file, index) in medical_record_files"
          :key="index"
        >
          <div class="col-8 border-top border-dark pl-1 hodo-print">
            <span class="fs-22">- {{ file.type }}</span>
          </div>
          <div
            class="col-4 border-left border-top border-dark pl-1 text-center hodo-print"
          >
            <span class="fs-22">{{ file.count }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-8 border-top border-dark pl-1 hodo-print">
            <span class="fs-22">- Toàn bộ hồ sơ</span>
          </div>
          <div
            class="col-4 border-left border-top border-dark pl-1 text-center hodo-print"
          >
            <span class="fs-22">{{ getSum() }}</span>
          </div>
        </div>
      </div>
      <div class="col-4 border-right border-dark p-0 hodo-print">
        <div class="flex flex-col justify-between fs-22">
          <div class="font-bold text-center mb-14 fs-22">Người giao hồ sơ:</div>
          <div
            class="flex px-1 border-bottom border-dark"
            :class="form.profile_deliver ? 'justify-center' : ''"
          >
            <span class="whitespace-nowrap mr-1 fs-22">Họ tên:</span>
            <span
              v-if="!form.profile_deliver"
              class="block content-none w-full"
            ></span>
            <span class="fs-22" v-else>{{ form.profile_deliver }}</span>
          </div>
        </div>
        <div class="flex flex-col justify-between fs-22">
          <div class="font-bold text-center mb-14 fs-22">Người nhận hồ sơ:</div>
          <div
            class="flex px-1"
            :class="form.profile_recipient ? 'justify-center' : ''"
          >
            <span class="whitespace-nowrap mr-1 fs-22">Họ tên:</span>
            <span
              v-if="!form.profile_recipient"
              class="block content-none w-full"
            ></span>
            <span class="fs-22" v-else>{{ form.profile_recipient }}</span>
          </div>
        </div>
      </div>
      <div class="col-4 p-0 flex flex-col hodo-print">
        <div class="flex flex-col justify-between flex-1 fs-22">
          <div class="text-center">
            <div class="flex justify-center pt-2">
              <div class="fs-22">
                Ngày
                <span>.......</span>
              </div>
              <div class="fs-22">
                tháng
                <span>.......</span>
              </div>
              <div class="fs-22">
                năm
                <span>.......</span>
              </div>
            </div>
            <div class="font-bold text-center flex-none fs-22">
              Bác sĩ điều trị
            </div>
          </div>
          <div
            class="font-bold fs-22 text-center"
            v-if="form && form.treatment_doctor && form.treatment_doctor.User"
          >
            {{ form.treatment_doctor.User.name || "" }}
          </div>
          <!-- <div  class="pl-1 text-center fs-22">Họ và tên: {{ treat_doctor && treat_doctor.name }}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewDetailBTongKet',
  props: ['form', 'isEdit', 'medical_record_files', 'treat_doctor'],
  methods: {
    getSum () {
      if (!this.medical_record_files) return ''
      const sum = this.medical_record_files.reduce((partialSum, a) => {
        if (!a.count) return partialSum
        return partialSum + parseInt(a.count)
      }, 0)

      return sum > 0 ? sum : ''
    }
  }
}
</script>

<style scoped>
.min-h-12 {
  min-height: 3rem;
}

.border-b-0 {
  border-bottom: none !important;
}

.fontForAll {
  font-family: "Times New Roman", "Tinos", Times, serif !important;
}

.box-editor /deep/ p {
  margin-bottom: 0;
}
</style>
