<template>
  <div class="mt-6">
    <div class="text-2xl font-semibold">IV. TÌNH TRẠNG RA VIỆN</div>
    <div class="mt-3 row">
      <div class="col-md-6">
        <div>
          <label for="nam" class="fs-14 txt-grey-900">24. Kết quả điều trị</label>
        </div>
        <div class="flex mt-1.5">
          <label for="khoi" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="khoi"
              :value="1"
              class="mr-2"
              v-model="form.treatment_results"
            /> 1. Khỏi
          </label>
          <label for="dogiam" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="dogiam"
              :value="2"
              class="mr-2"
              v-model="form.treatment_results"
            />2. Đỡ,
            giảm
          </label>
          <label for="khongthaydoi" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="khongthaydoi"
              :value="3"
              class="mr-2"
              v-model="form.treatment_results"
            />3.Không thay
            đổi
          </label>
        </div>
        <div class="flex mt-3">
          <label for="nanghon" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="nanghon"
              :value="4"
              class="mr-2"
              v-model="form.treatment_results"
            />4.Nặng
            hơn
          </label>
          <label for="tuvong" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="tuvong"
              :value="5"
              class="mr-2"
              v-model="form.treatment_results"
            />5.Tử vong
          </label>
          <label class="d-flex align-items-center">
            <button
              @click="form.treatment_results = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <label for="nam" class="fs-14 txt-grey-900">26. Tình trạng tử vong</label>
        </div>
        <div class="flex mt-1.5">
          <label for="dobenh" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="dobenh"
              :value="1"
              class="mr-2"
              v-model="form.mortality_status"
            /> 1. Do bệnh
          </label>
          <label for="dotaibien" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="dotaibien"
              :value="2"
              class="mr-2"
              v-model="form.mortality_status"
            /> 2.Do tai biến
            điều
            trị
          </label>
          <label for="type12khac" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type12khac"
              :value="3"
              class="mr-2"
              v-model="form.mortality_status"
            />3. Khác
          </label>
          <label class="d-flex align-items-center">
            <button
              @click="form.mortality_status = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
        <div class="flex mt-3">
          <label for="type13trong24h" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type13trong24h"
              :value="1"
              class="mr-2"
              v-model="form.mortality_at"
            />1. Trong 24 giờ
            vào
          </label>
          <label for="type13sau24h" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type13sau24h"
              :value="2"
              class="mr-2"
              v-model="form.mortality_at"
            />2. Sau 24 giờ vào
            viện
          </label>
          <label class="d-flex align-items-center">
            <button
              @click="form.mortality_at = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-6">
        <div>
          <label for="nam" class="fs-14 txt-grey-900">25. Giải phẫu bệnh (khi có sinh thiết):</label>
        </div>
        <div class="flex mt-1.5">
          <label for="lanhtinh" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="lanhtinh"
              :value="1"
              class="mr-2"
              v-model="form.biopsy_pathology"
            /> 1. Lành
            tính
          </label>
          <label for="biopsy_pathologynghi" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              type="radio"
              :disabled="!isEdit"
              id="biopsy_pathologynghi"
              :value="2"
              class="mr-2"
              v-model="form.biopsy_pathology"
            />2.
            Nghi ngờ
          </label>
          <label for="actinh" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="actinh"
              :value="3"
              class="mr-2"
              v-model="form.biopsy_pathology"
            />3.Ác tính
          </label>
          <label class="d-flex align-items-center">
            <button
              @click="form.biopsy_pathology = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>

      <div class="col-md-6 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">27. Nguyên nhân chính tử vong</label>
          <input
            v-model="form.mortality_name"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="form.mortality_code"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
    </div>

    <div class="mt-3 row">
      <div class="col-md-6 flex">
        <div>
          <label for="nam" class="fs-14 txt-grey-900">28. Khám nghiệm tử thi:</label>
        </div>
        <div class="ml-2 flex">
          <label for="type15yes" class="d-flex align-items-center mr-4 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type15yes"
              :value="1"
              class="mr-2"
              v-model="form.is_autopsy"
            />Có
          </label>
          <label for="type15no" class="d-flex align-items-center mr-4 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type15no"
              :value="0"
              class="mr-2"
              v-model="form.is_autopsy"
            />Không
          </label>
          <label class="d-flex align-items-center">
            <button
              @click="form.is_autopsy = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TinhTrangRaVien',
  props: ['form', 'isEdit']
}
</script>
